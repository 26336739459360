import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import './IndividualRecordsTableColumns.css';
import { format } from 'date-fns';
//Emojis to use 🟢 🔴

const columnHelper = createColumnHelper();
export const columns = [
    columnHelper.accessor(row => row.mls_code, {
        header: 'Agent MLS',
        id: 'agentMls',
        recordDataId: 'mls_code',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.agent_name, {
        header: 'Agent Name',
        id: 'agentName',
        recordDataId: 'agent_name',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.agent_team, {
        header: 'Agent Team',
        id: 'agentTeam',
        recordDataId: 'agent_team',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.agent_team, {
        header: 'Name Match',
        id: 'nameMatch',
        recordDataId: 'proposed_lead_ids',
        cell: (info) => (
            <div style={{textAlign: 'center'}}><input readOnly type='checkbox' checked={info.row.original?.proposed_lead_ids !== null} /></div>
        )
    }),
    columnHelper.accessor(row => (
        `${row.listing_address}, ${row.listing_city}, ${row.listing_state} ${row.listing_zip}`
    ), {
        header: 'Listing Address',
        id: 'listingAddress',
        recordDataId: 'listing_address',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.listing_close_date, {
        header: 'Listing Close Date',
        id: 'listingCloseDate',
        recordDataId: 'listing_close_date',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.transaction_seller, {
        header: "Transaction Seller",
        id: 'transactionSeller',
        recordDataId: 'transaction_seller',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.transaction_buyer, {
        header: "Transaction Buyer",
        id: 'transactionBuyer',
        recordDataId: 'transaction_buyer',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.original?.auditInfo?.auditor_status, {
        header: "Auditor Status",
        id: 'auditorStatus',
        recordDataId: 'auditor_status',
        style: { textAlign: 'center'},
        cell: (info) => (
            <div style={{textAlign: 'center'}}>{info?.row?.original?.auditInfo?.auditor_status ? '🟢' : '🔴'}</div>
        )
    }),
    columnHelper.accessor(row => row.original?.auditInfo?.manager_status, {
        header: "Manager Status",
        id: 'managerStatus',
        recordDataId: 'manager_status',
        meta: {
            style: { textAlign: 'center'},
        }, 
        cell: (info) => (
            <div style={{textAlign: 'center'}}>{info?.row?.original?.auditInfo?.manager_status ? '🟢' : '🔴'}</div>
        )
    }),
    columnHelper.accessor(row => row.original?.auditInfo?.final_disposition, {
        header: "Final Disposition",
        id: 'finalDisposition',
        recordDataId: 'final_disposition',
        style: { textAlign: 'center'},
        cell: (info) => (
            <div style={{textAlign: 'center'}}>{info?.row?.original?.auditInfo?.final_disposition ? '🟢' : '🔴'}</div>
        )
    }),
    columnHelper.accessor(row => row.original?.auditInfo?.last_modified_datetime, {
        header: "Last Modified Date",
        id: 'lastModifiedDatetime',
        recordDataId: 'last_modified_datetime',
        cell: (info) => {
            const dateString = info?.row?.original?.auditInfo?.last_modified_datetime;
            let formattedDate = "";
    
            if (dateString) {
                try {
                    const date = new Date(dateString);
                    formattedDate = format(date, "M/d/yy h:mma").toLowerCase();
                } catch (e) {
                    console.error("Invalid date format", e);
                    formattedDate = dateString;
                }
            }
    
            return (
                <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                    {formattedDate}
                </div>
            );
        }
    }),
    columnHelper.accessor(row => row.mls_closed_listing_id, {
        header: "View Record",
        id: 'agentRecords',
        cell: (info) => (
            <button
                onClick={() => {
                    const url = `/listing/${info.row.original.mls_closed_listing_id}/agent/${info.row.original.agent_huddle3_contact_id}/transaction/${info.row.original.parcel_transaction_unique_id}/surrogate/${info.row.original.parcel_surrogate_unique_id}`;
                    window.open(url, '_blank');
                }}
            >
                View Record
            </button>
        )
    })
];
