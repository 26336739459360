import React, { useContext, useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { nullFilter, managerStatusOptions, clearFilter } from "../utils";
import { selectStyles } from "../styles";
import { appContext } from "../AppProvider";

const ManagerStatusFilter = ({ onFilterChange, value }) => {
  const { setStatus, setGroupedRecordOffset, setRecordOffset } =
    useContext(appContext);

  const navigate = useNavigate();

  const findSelectedOption = (selectedValues) => {
    if (!selectedValues) return null;
    if (selectedValues.includes("noStatus")) selectedValues = ["noStatus"];
    let result = clearFilter
      .concat(nullFilter.concat(managerStatusOptions))
      .filter((option) => selectedValues.includes(option.value));
    return result;
  };

  const [selectedOption, setSelectedOption] = useState(
    findSelectedOption(value)
  );

  useEffect(() => {
    setSelectedOption(findSelectedOption(value));
  }, [value]);

  const handleManagerStatusSelection = (selectedOptions) => {
    setGroupedRecordOffset(0);
    setRecordOffset(0);

    let selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    // If "Clear Filter" is selected, clear the input
    if (selectedValues.includes(false) || selectedOptions.length === 0) {
        selectedValues = '';
        selectedOptions = '';
    }

    setStatus((prevStatus) => ({ ...prevStatus, manager: selectedValues }));
    onFilterChange({
      managerStatus: selectedValues.length > 0 ? selectedValues : "noStatus",
    });
    setSelectedOption(selectedOptions);

    if (selectedValues.length === 0) {
      const currentParams = new URLSearchParams(location.search);
      currentParams.delete("managerStatus");
      navigate(location.pathname + "?" + currentParams.toString());
    }
};

  return (
    <Select
      options={clearFilter.concat(nullFilter.concat(managerStatusOptions))}
      placeholder={"Select Manager Status"}
      onChange={handleManagerStatusSelection}
      value={selectedOption}
      styles={selectStyles}
      isMulti
    />
  );
};

ManagerStatusFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

export default ManagerStatusFilter;