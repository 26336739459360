import React, { useContext, useState, useEffect } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import {nullFilter, finalDispositionStatusOptions, clearFilter} from "../utils";
import {selectStyles} from "../styles";
import {appContext} from "../AppProvider";

const FinalDispositionFilter = ({ onFilterChange, value }) => {
    const navigate = useNavigate();

    const {
        setStatus,
        setGroupedRecordOffset,
        setRecordOffset
    } = useContext(appContext);

    const findSelectedOption = (selectedValues) => {
        if (!selectedValues) return null;
        if (selectedValues.includes("noStatus")) selectedValues = ["noStatus"];
        let result = clearFilter
          .concat(nullFilter.concat(finalDispositionStatusOptions))
          .filter((option) => selectedValues.includes(option.value));
        return result;
      };

    const [selectedOption, setSelectedOption] = useState(
        findSelectedOption(value)
      );
    
      useEffect(() => {
        setSelectedOption(findSelectedOption(value));
      }, [value]);

    const handleFinalDispositionSelection = (selectedOptions) => {
        setGroupedRecordOffset(0);
        setRecordOffset(0);

        let selectedValues = selectedOptions
          ? selectedOptions.map((option) => option.value)
          : [];

        // If "Clear Filter" is selected, clear the input
        if (selectedValues.includes(false) || selectedOptions.length === 0) {
            selectedValues = '';
            selectedOptions = '';
        }

        setStatus((prevStatus) => ({ ...prevStatus, finalDisposition: selectedValues }));
        onFilterChange({
          finalDispositionStatus: selectedValues.length > 0 ? selectedValues : "noStatus",
        });
        setSelectedOption(selectedOptions);

        if (selectedValues.length === 0) {
          const currentParams = new URLSearchParams(location.search);
          currentParams.delete("finalDispositionStatus");
          navigate(location.pathname + "?" + currentParams.toString());
        }
    };

    return (
        <Select
          options={clearFilter.concat(nullFilter.concat(finalDispositionStatusOptions))}
          placeholder={"Select Final Disposition Status"}
          onChange={handleFinalDispositionSelection}
          value={selectedOption}
          styles={selectStyles}
          isMulti
        />
      );
};

FinalDispositionFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

export default FinalDispositionFilter;