import React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import ViewAgentButton from "./ViewAgentButton";


const columnHelper = createColumnHelper();
export const columns = [
    columnHelper.accessor(row => row.mls_code, {
        header: 'Agent MLS',
        id: 'mls',
        recordDataId: 'mls_code',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.agent_name, {
        header: 'Agent Name',
        id: 'agentName',
        recordDataId: 'agent_name',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.agent_warehouse_generated_team, {
        header: 'Agent Team',
        id: 'agentTeam',
        recordDataId: 'agent_warehouse_generated_team',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row?.transactions_aggregate?.aggregate?.count !== undefined ? row.transactions_aggregate.aggregate.count : '', {
        header: 'Number of Records',
        id: 'transactionCount',
        recordDataId: 'transaction_count',
        cell: info => info.getValue()
    }),
    // the following button can be changed to use navigate to route to agent view
    columnHelper.accessor(row => row.agent_huddle3_contact_id, {
        header: "View Agent",
        id: 'agentRecords',
        cell: info => <ViewAgentButton agentContactId={info.getValue()} />
    })
];
