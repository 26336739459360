import React, {useContext} from "react";
import GroupedRecordsTable from "../components/GroupedRecordsTable";
import IndividualRecordsTable from "../components/IndividualRecordsTable";
import RecordFilterContainer from "./RecordFilterContainer";
import {appContext} from "../AppProvider";
import RecordSearchBar from "./RecordSearchBar";

const RecordFinderContainer = () => {
    const { groupByAgent, setGroupByAgent, refetch } = useContext(appContext);

    const toggleGrouping = () => {
        setGroupByAgent(!groupByAgent);
    }

    return (
        <div className="record-view-container">
            <RecordSearchBar refetch={refetch}></RecordSearchBar>
            <RecordFilterContainer groupByAgent={groupByAgent} toggleGrouping={toggleGrouping} />
            <div id="record-table-container">
                { groupByAgent ?
                    <GroupedRecordsTable />
                    :
                    <IndividualRecordsTable />
                }
            </div>
        </div>
    );
}

export default RecordFinderContainer;
