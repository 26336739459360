import React, { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
export const DateInformation = ({ auditRecord }) => {
  const [auditRecordData, setAuditRecordData] = useState(null);

  useEffect(() => {
    if (auditRecord) {
      setAuditRecordData(auditRecord);
    }
  }, [auditRecord]);

  return (
    <Stack style={{ fontSize: "14px" }}>
      {auditRecordData ? (
        <>
          <div>
            <label>
              <div style={{ fontWeight: "bold", marginRight: "10px" }}>
                Listing Date Information:
              </div>
              <div mr={4}>
                {auditRecordData?.length == 0 ||
                auditRecordData?.listing_close_date == null ? (
                  "No Data"
                ) : (
                  <span>{auditRecordData.listing_close_date}</span>
                )}
              </div>
            </label>
          </div>
          <div>
            <label>
              <div style={{ fontWeight: "bold", marginRight: "10px" }}>
                Parcel Date Information:
              </div>
              <div mr={4}>
                {auditRecordData?.length == 0 ||
                auditRecordData?.parcel_transfer_date == null ? (
                  "No Data"
                ) : (
                  <span>{auditRecordData.parcel_transfer_date}</span>
                )}
              </div>
            </label>
          </div>
          {/* <Checkbox> match?</Checkbox> */}
        </>
      ) : (
        "Loading..."
      )}
    </Stack>
  );
};
