import React, { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
export const PriceInformation = ({ auditRecord }) => {
  const [auditRecordData, setAuditRecordData] = useState(null);

  useEffect(() => {
    if (auditRecord) {
      setAuditRecordData(auditRecord);
    }
  }, [auditRecord]);

  function currencyFormat(num) {
    return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return (
    <Stack style={{ fontSize: "14px" }}>
      {auditRecordData ? (
        <>
          <div>
            <label>
              <div style={{ fontWeight: "bold", marginRight: "10px" }}>
                Listing Price Information
              </div>
              <div mr={4}>
                {auditRecordData?.length == 0 ||
                auditRecordData.listing_close_price == null ? (
                  "No Data"
                ) : (
                  <span>
                    {currencyFormat(
                      Number(auditRecordData.listing_close_price)
                    )}
                  </span>
                )}
              </div>
            </label>
          </div>
          <div>
            <label>
              <div style={{ fontWeight: "bold", marginRight: "10px" }}>
                Parcel Price Information
              </div>
              <div>
                {auditRecordData?.length == 0 ||
                auditRecordData.parcel_transfer_value == null ? (
                  "No Data"
                ) : (
                  <span>
                    {currencyFormat(
                      Number(auditRecordData.parcel_transfer_value)
                    )}
                  </span>
                )}
              </div>
            </label>
          </div>
          {/* <Checkbox> match?</Checkbox> */}
        </>
      ) : (
        "Loading..."
      )}
    </Stack>
  );
};
