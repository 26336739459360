import React from 'react';
import PropTypes from "prop-types";


function Pagination({ currentPage, totalPages, onPageChange }) {
    const pagesToShow = 5;
    const pages = [...Array(totalPages).keys()].map(i => i + 1);

    // Determine the range of pages to show
    let startPage = Math.max(currentPage - Math.floor(pagesToShow / 2), 1);
    let endPage = Math.min(startPage + pagesToShow - 1, totalPages);

    // Adjust the start page if we're at the end of the range
    if (endPage - startPage + 1 < pagesToShow) {
        startPage = Math.max(endPage - pagesToShow + 1, 1);
    }

    return (
        <div style={{ textAlign: 'end', display: 'flex', float: 'right'}}>
            {pages.slice(startPage - 1, endPage).map(page => (
                <span
                    key={page}
                    style={{ fontWeight: 'bold', cursor: 'pointer', color: page === currentPage ? '#8679fd' : 'blue' }}
                    onClick={() => onPageChange(page)}
                >
                    {page}
                </span>
            ))}
        </div>
    );
}

Pagination.propTypes = {
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    onPageChange: PropTypes.func,
  };

export default Pagination;