import {AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Card, CardBody} from "@chakra-ui/react";
import {AgentInfoSection} from "./AgentInfoSection";
import React from "react";
import PropTypes from "prop-types";


const AgentAccordionItem = ({loading, agentInfo, auditRecord}) => {
    return (
        <AccordionItem>
            <h2>
                <AccordionButton color="white" bg="#18a02f">
                    <Box
                        as="span"
                        flex="1"
                        textAlign="center"
                        fontWeight="bold"
                    >
                        Agent Information
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel>
                <Card>
                    <CardBody>
                        {loading ? (
                            "Loading..."
                        ) : (
                            <AgentInfoSection
                                agentInfo={agentInfo}
                                auditRecord={auditRecord}
                            ></AgentInfoSection>
                        )}
                    </CardBody>
                </Card>
            </AccordionPanel>
        </AccordionItem>
    )
}

AgentAccordionItem.propTypes = {
    loading: PropTypes.bool.isRequired,
    agentInfo: PropTypes.object,
    auditRecord: PropTypes.object
}

export default AgentAccordionItem;
