import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


const StickyBar = ({auditRecord, agentInfo}) => {
    const [isScrolled, setIsScrolled] = useState(false);

    const checkScroll = () => {
        if (window.scrollY > 0) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScroll);
        return () => {
            window.removeEventListener('scroll', checkScroll);
        };
    }, []);

    return (
        <div style={{ position: 'sticky', top: 0, display: isScrolled ? 'block' : 'none', zIndex: 999, background: 'white', border: '1px solid black', textAlign: 'center'}}>
            <table style={{ width: '100%', margin: '0 auto'}}>
                <thead>
                    <tr>
                        <th>Agent MLS</th>
                        <th>Agent Name</th>
                        <th>Agent Team</th>
                        <th>Listing Address</th>
                        <th>Listing Close Date</th>
                        <th>Transaction Buyer</th>
                        <th>Transaction Seller</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{agentInfo?.mls_code}</td>
                        <td>{agentInfo?.agent_name}</td>
                        <td>{auditRecord?.agent_team}</td>
                        <td>{auditRecord?.listing_address}</td>
                        <td>{auditRecord?.listing_close_date}</td>
                        <td>{auditRecord?.transaction_buyer}</td>
                        <td>{auditRecord?.transaction_seller}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
StickyBar.propTypes = {
    agentInfo: PropTypes.object,
    auditRecord: PropTypes.object
}

export default StickyBar;