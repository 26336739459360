import React, { useContext } from 'react';
import './RecordSearchBar.css';
import { appContext } from '../AppProvider';
import { FaSearch } from 'react-icons/fa';
import { GrClose } from "react-icons/gr";

const RecordSearchBar = () => {

const { searchTerm, setSearchTerm} = useContext(appContext);

  return (
    <div className='record-search-bar-container'>
        <div className='search-bar-width-limit'>
            <div className="input-icon">
                <input
                    className='record-search-bar'
                    type="text"
                    placeholder='Search for a record by Name, Address or Listing Id...'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm === '' ? <FaSearch className="icon-search" /> : <GrClose className="icon-close" onClick={() => setSearchTerm('')} />}
                
            </div>
        </div>
    </div>
  );
};

export default RecordSearchBar;
