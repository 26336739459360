import React, {useContext} from "react";
import PropTypes from "prop-types";
import {appContext} from "../../AppProvider";

const ViewAgentButton = ({ agentContactId }) => {

    const {
        setAgentFilter,
        setTeamFilter,
        setGroupedRecordOffset,
        setRecordOffset,
        setGroupByAgent
    } = useContext(appContext);

    function handleViewAgent () {
        setAgentFilter(agentContactId);
        setTeamFilter(null);
        setGroupedRecordOffset(0);
        setRecordOffset(0);
        setGroupByAgent(false);
    }

    return (
        <button
            onClick={() => handleViewAgent()}
            className="next-page-button"
        >
            View Agent
        </button>
    );
}

ViewAgentButton.propTypes = {
    agentContactId: PropTypes.string.isRequired,
}

export default ViewAgentButton;
