import { gql } from "@apollo/client";


export const UPSERT_PARCEL_ADDRESS_MATCHES = gql`
  mutation UpsertParcelAddressMatches(
    $listing_id: bigint
    $parcel_address_matches: Boolean
    $parcel_surrogate_unique_id: String
    $parcel_transaction_unique_id: String
  ) {
    insert_audit_matches_one(
      object: {
        listing_id: $listing_id
        parcel_address_matches: $parcel_address_matches
        parcel_surrogate_unique_id: $parcel_surrogate_unique_id
        parcel_transaction_unique_id: $parcel_transaction_unique_id
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: parcel_address_matches
        where: {
          listing_id: { _eq: $listing_id }
          parcel_surrogate_unique_id: { _eq: $parcel_surrogate_unique_id }
          parcel_transaction_unique_id: { _eq: $parcel_transaction_unique_id }
        }
      }
    ) {
      listing_id
    }
  }
`;

const standardMutationObjectContents = `
    agent_huddle3_contact_id: $agent_huddle3_contact_id
    listing_id: $listing_id
    parcel_surrogate_unique_id: $parcel_surrogate_unique_id
    parcel_transaction_unique_id: $parcel_transaction_unique_id
`;

const standardMutationVariables = `
    $agent_huddle3_contact_id: String = ""
    $listing_id: bigint
    $parcel_surrogate_unique_id: String = ""
    $parcel_transaction_unique_id: String = ""
`;

export const UPDATE_AUDITOR_NOTES = gql`
  mutation UpdateAuditorNotes(
    $auditor_notes: String = ""
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        auditor_notes: $auditor_notes
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [auditor_notes]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      auditor_notes
    }
  }
`;

export const UPDATE_MANAGER_NOTES = gql`
  mutation UpdateManagerNotes(
    $manager_notes: String = ""
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        manager_notes: $manager_notes
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [manager_notes]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      manager_notes
    }
  }
`;


export const UPDATE_MAIL_MERGE_TEXT = gql`
  mutation UpdateMailMergeText(
    $mail_merge_text: String = ""
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        mail_merge_text: $mail_merge_text
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [mail_merge_text]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      mail_merge_text
    }
  }
`;

export const UPDATE_TRANSACTION_BUYER_STATUS = gql`
  mutation UpdateTransactionBuyerStatus(
    $transaction_buyer_status: String = ""
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        transaction_buyer_status: $transaction_buyer_status
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [transaction_buyer_status]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      transaction_buyer_status
    }
  }
`;

export const UPDATE_TRANSACTION_SELLER_STATUS = gql`
  mutation UpdateTransactionSellerStatus(
    $transaction_seller_status: String = ""
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        transaction_seller_status: $transaction_seller_status
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [transaction_seller_status]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      transaction_seller_status
    }
  }
`;

export const UPDATE_AUDITOR_STATUS = gql`
  mutation UpdateAuditorStatus(
    $auditor_status: String = "",
    $manager_status: String = "",
    $final_disposition: String = "",
    $auditor_status_last_modified: timestamptz = "now()",
    $manager_status_last_modified: timestamptz = "now()",
    $final_disposition_last_modified: timestamptz = "now()",
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        auditor_status: $auditor_status,
        manager_status: $manager_status,
        final_disposition: $final_disposition,
        auditor_status_last_modified: $auditor_status_last_modified,
        manager_status_last_modified: $manager_status_last_modified,
        final_disposition_last_modified: $final_disposition_last_modified,
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction,
        update_columns: [auditor_status, manager_status, final_disposition, auditor_status_last_modified, manager_status_last_modified, final_disposition_last_modified],
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      auditor_status
    }
  }
`;


export const UPDATE_MANAGER_STATUS = gql`
  mutation UpdateManagerStatus(
    $manager_status: String = "",
    $manager_status_last_modified: timestamptz = "now()",
    $status_escalation_datetime: timestamp,
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        manager_status: $manager_status,
        manager_status_last_modified: $manager_status_last_modified,
        status_escalation_datetime: $status_escalation_datetime,
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [manager_status, manager_status_last_modified, status_escalation_datetime]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      manager_status
    }
  }
`;

export const UPDATE_FINAL_DISPOSITION_STATUS = gql`
  mutation UpdateFinalDispositionStatus(
    $final_disposition: String = "",
    $final_disposition_last_modified: timestamptz = "now()",
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        final_disposition: $final_disposition,
        final_disposition_last_modified: $final_disposition_last_modified,
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [final_disposition, final_disposition_last_modified]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      final_disposition
    }
  }
`;


export const UPDATE_MATCHED_ID = gql`
  mutation UpdateMatchedId(
    $sale_id: String = ""
    $confirmed_lead_id: String = ""
    ${standardMutationVariables}
  ) {
    insert_audit_process_audit_info_one(
      object: {
        sale_id: $sale_id
        confirmed_lead_id: $confirmed_lead_id
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [sale_id, confirmed_lead_id]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      sale_id
    }
  }
`;
export const UPDATE_OPPORTUNITY_ID = gql`
    mutation UpdateOpportunityId(
        $opportunity_id: String = ""
        ${standardMutationVariables}
    ) {
        insert_read_only_agent_auditor_listings_transactions_view(
            objects: {
              opportunity_id: $opportunity_id
              ${standardMutationObjectContents}
            }
            on_conflict: {
                update_columns: [opportunity_id]
                where: {
                    listing_id: { _eq: $listing_id }
                    agent_huddle3_contact_id: { _eq: $agent_huddle3_contact_id }
                    parcel_surrogate_unique_id: { _eq: $parcel_surrogate_unique_id }
                    parcel_transaction_unique_id: { _eq: $parcel_transaction_unique_id }
                }
            }
        ) {
            listing_id
        }
    }
`;


export const UPDATE_POTENTIAL_LEADS_ID = gql`
  mutation UpdatePotentialLeadsID(
    $potential_lead_ids: jsonb
    ${standardMutationVariables}

  ) {
    insert_audit_process_audit_info_one(
      object: {
        potential_lead_ids: $potential_lead_ids
        ${standardMutationObjectContents}
      }
      on_conflict: {
        constraint: unique_audit_listing_parcel_transaction
        update_columns: [potential_lead_ids]
        where: { listing_id: { _eq: $listing_id } }
      }
    ) {
      listing_id
    }
  }
`;

