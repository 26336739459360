import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import {groupBadgeStyles, groupStyles, selectStyles} from "../styles";
import {
    formatAgentsAndTeamsForDropdown,
    clearFilter
} from "../utils";
import {appContext} from "../AppProvider";


const AgentFilter = ({ onFilterChange, agentValue, teamValue }) => {


    const [clearInput, setClearInput] = useState(false);
    
    const navigate = useNavigate();

    const {
        agentData,
        agentsLoading: loading,
        setAgentFilter,
        setTeamFilter,
        setGroupedRecordOffset,
        setRecordOffset
    } = useContext(appContext);

    const [agentList, setAgentList] = useState(clearFilter);

    const handleAgentOrTeamSelection = (target) => {
        setGroupedRecordOffset(0);
        setRecordOffset(0);
        if (target.value === false) {
            setAgentFilter(false);
            setTeamFilter(false);
            agentValue = null;
            teamValue = null;
            const currentParams = new URLSearchParams(location.search);
            currentParams.delete('agentFilter'); 
            currentParams.delete('teamFilter'); 
            setClearInput(true);
            navigate(location.pathname + '?' + currentParams.toString()); // Navigate to the new URL      
        }

        const { filter, category } = target;

        if (category === 'teams') {
            setClearInput(false);
            setAgentFilter(false);
            setTeamFilter(filter);
            onFilterChange({ teamFilter: filter, agentFilter: false});
        }

        if (category === 'agents') {
            setClearInput(false);
            setTeamFilter(false);
            setAgentFilter(filter);
            onFilterChange({ agentFilter: filter, teamFilter: false });
        }
    }

    useEffect(() => {
        const agents = formatAgentsAndTeamsForDropdown(agentData);
        setAgentList(clearFilter.concat(agents));

    }, [agentData]);

    const formatGroupLabel = (agentsAndTeamsData) => (
        <div style={groupStyles}>
            <span>{agentsAndTeamsData.label}</span>
            <span style={groupBadgeStyles}>{agentsAndTeamsData?.options?.length}</span>
        </div>
    );

    if (loading) {
        return (
            <Select
                options={clearFilter}
                placeholder="Loading Teams and Agents..."
                styles={selectStyles}
            />
        );
    }

    const findOption = (list, value) => {
        const found = list?.options.find(option => option.filter === value);
        return found;
      };
      
      const selectedOption = findOption(agentValue != null ? agentList[1] : agentList[2], agentValue || teamValue);
      

    return (
        <Select
            options={agentList}
            placeholder="Select Team or Agent"
            onChange={handleAgentOrTeamSelection}
            styles={selectStyles}
            value={clearInput ? '' : selectedOption}
            formatGroupLabel={formatGroupLabel}
        />
    );
}

AgentFilter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    agentValue: PropTypes.string,
    teamValue: PropTypes.string,
  };
  

export default AgentFilter;
