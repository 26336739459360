export const nullFilter = [{ value: "noStatus", label: "No Status" }];
export const clearFilter = [{ value: false, label: "Clear Filter" }];

function formatSelectData(data) {
    let formattedData = [];
    for (const d of data) {
        formattedData.push({ value: d.toLowerCase(), label: d });
    }
    return formattedData;
}

function removeDuplicates(array) {
    return array.filter((item, index) => array.indexOf(item) === index);
}

export function formatMlsData(mlsData) {
    if (mlsData === undefined) return [];
    let mlses = [];
    for (const mls of mlsData) {
        mlses.push(mls.code);
    }
    mlses.sort();

    return formatSelectData(mlses);
}

export const auditorStatusOptions = [
    { value: "Definitive", label: "Definitive" },
    { value: "Likely", label: "Likely" },
    { value: "Possible", label: "Possible" },
    { value: "Clear", label: "Clear" },
    { value: "Escalate", label: "Escalate" },
    { value: "Failed QA", label: "Failed QA" },
];

export const managerStatusOptions = [
    { value: "Definitive", label: "Definitive" },
    { value: "Likely", label: "Likely" },
    { value: "Possible", label: "Possible" },
    { value: "Clear", label: "Clear" },
    { value: "Escalate", label: "Escalate" },
];

export const finalDispositionStatusOptions = [
    {
        value: "Entered in Huddle - Missed Subsequent Sale",
        label: "Entered in Huddle - Missed Subsequent Sale",
    },
    { value: "Entered in Huddle", label: "Entered in Huddle" },
    {
        value: "Clear - Management Decision",
        label: "Clear - Management Decision",
    },
    {
        value: "Clear - Pre-existing relationship",
        label: "Clear - Pre-existing relationship",
    },
    { value: "Clear - previously paid", label: "Clear - previously paid" },
    { value: "Clear", label: "Clear" },
];

export const displayValues = {
    lister: "Lister",
    buyer: "Buyer",
    lister_and_buyer: "Lister and Buyer",
    buyer_and_lister: "Buyer and Lister",
};

export function formatAgentsAndTeamsForDropdown(agentData) {
    if (agentData === undefined) return [];
    let formattedAgents = [];
    let teams = [];

    for (const row of agentData) {
        // custom format for searching and filtering purposes
        formattedAgents.push({
            value: "agents:" + row.agent_name.toLowerCase(),
            label: row.agent_name,
            category: "agents",
            filter: row.agent_huddle3_contact_id,
        });
        let team = row.agent_warehouse_generated_team;
        if (team) {
            teams.push(team);
        }
    }

    teams = removeDuplicates(teams);
    let formattedTeams = [];
    for (const team of teams) {
        formattedTeams.push({
            value: "teams:" + team.toLowerCase(),
            label: team,
            category: "teams",
            filter: team.toLowerCase(),
        });
    }

    return [
        {
            label: "Agents",
            options: removeDuplicates(formattedAgents),
        },
        {
            label: "Teams",
            options: formattedTeams,
        },
    ];
}

function includesNoStatus(status) {
    return Array.isArray(status) && status.includes("noStatus");
}

export function formatIndexPageFilters(
    mls,
    agent,
    team,
    auditorStatus,
    managerStatus,
    finalDisposition,
    searchTerm
) {
    let filters = {};

    filters.mlsQueryPart = mls ? `mls_code: { _ilike: "${mls}" }` : "";

    filters.auditStatusAndLastNameMatches = `_or: [{ proposed_lead_ids: { _neq: "" } }, { auditInfo: { auditor_status: { _neq: "" } } }]`;

    filters.agentQueryPart = agent
        ? `agent_huddle3_contact_id: { _ilike: "${agent}" }`
        : "";
    filters.groupedTeamQueryPart = team
        ? `agent_warehouse_generated_team: { _ilike: "${team}" }`
        : "";
    filters.individualTeamQueryPart = team
        ? `agent_team: { _ilike: "${team}" }`
        : "";
    let searchById = "";
    if (/^\d+$/.test(searchTerm)) {
        const bigIntValue = BigInt(searchTerm);
        searchById = `{mls_closed_listing_id: { _in: "${bigIntValue}" }},`;
    }
    filters.searchQueryPart = searchTerm
        ? `_or: [ {listing_address: { _iregex: "${searchTerm}" }}, ${searchById} {transaction_buyer: {_iregex: "${searchTerm}"}}, {transaction_seller: {_iregex: "${searchTerm}"}}]`
        : "";

    filters.noNullLastNameMatches =
        isValidFilterValue(auditorStatus) && includesNoStatus(auditorStatus)
            ? 'proposed_lead_ids: { _neq: "" },'
            : "";

    filters.noPublicInfoFilterPart = `
        _and:[
        {
            _not:{
                parcel_surrogate_unique_id: { _eq: "" }
                parcel_transaction_unique_id: { _eq: "" }
                
            },
            ${filters.noNullLastNameMatches}
            ${filters.searchQueryPart}
        }]
    `;

    filters.individualAuditInfoQueryPart = "";
    filters.groupedTableAuditInfoQuery = "";

    if (
        isValidFilterValue(auditorStatus) ||
        isValidFilterValue(managerStatus) ||
        isValidFilterValue(finalDisposition)
    ) {
        let auditInfo = `
              ${formatStatusFilter(auditorStatus, "auditor_status")}
              ${formatStatusFilter(managerStatus, "manager_status")}
              ${formatStatusFilter(finalDisposition, "final_disposition")}
        `;

        let nullAuditInfoFilter = "";

        if (
            // All include 'noStatus'
            (includesNoStatus(auditorStatus) &&
                includesNoStatus(managerStatus) &&
                includesNoStatus(finalDisposition)) ||
            // One includes 'noStatus' and the others are empty
            (includesNoStatus(auditorStatus) &&
                managerStatus === "" &&
                finalDisposition === "") ||
            (includesNoStatus(managerStatus) &&
                auditorStatus === "" &&
                finalDisposition === "") ||
            (includesNoStatus(finalDisposition) &&
                auditorStatus === "" &&
                managerStatus === "") ||
            // Two include 'noStatus' and the other is empty
            (includesNoStatus(auditorStatus) &&
                includesNoStatus(managerStatus) &&
                finalDisposition === "") ||
            (includesNoStatus(auditorStatus) &&
                includesNoStatus(finalDisposition) &&
                managerStatus === "") ||
            (includesNoStatus(managerStatus) &&
                includesNoStatus(finalDisposition) &&
                auditorStatus === "")
        ) {
            nullAuditInfoFilter = `
            _or: [
                { _not: { auditInfo: {} }},
                {auditInfo: {auditor_status: {_eq: ""}}}, 
                {_and: [${auditInfo}]}
              ]
            `;
            filters.auditStatusAndLastNameMatches = "";
        } else {
            if (
                !includesNoStatus(auditorStatus) &&
                !includesNoStatus(managerStatus) &&
                !includesNoStatus(finalDisposition)
            ) {
                auditInfo = `auditInfo: {${auditInfo}}`;
            }

            //case when auditorStatus, managerStatus, finalDisposition = noStatus AND the other have values
            if (
                (includesNoStatus(auditorStatus) &&
                    includesNoStatus(managerStatus) &&
                    includesNoStatus(finalDisposition)) ||
                (includesNoStatus(auditorStatus) &&
                    Array.isArray(managerStatus) ||
                    Array.isArray(finalDisposition)) ||
                (includesNoStatus(managerStatus) &&
                    Array.isArray(auditorStatus) ||
                    Array.isArray(finalDisposition)) ||
                (includesNoStatus(finalDisposition) &&
                    Array.isArray(auditorStatus) ||
                    Array.isArray(managerStatus))
            ) {
              auditInfo = buildDynamicAuditInfo(auditorStatus, managerStatus, finalDisposition);
            }
            


        }


        const groupedTableAuditInfoQuery = `
                ${formatStatusFilter(auditorStatus, "auditor_status")}
                ${formatStatusFilter(managerStatus, "manager_status")}
                ${formatStatusFilter(finalDisposition, "final_disposition")}
        `;
        filters.groupedTableAuditInfoQuery = groupedTableAuditInfoQuery;
        filters.individualAuditInfoQueryPart =
            nullAuditInfoFilter != "" ? nullAuditInfoFilter : auditInfo;
    }

    return filters;
}

function isValidFilterValue(value) {
    if (value === null) {
        return true;
    }
    return value;
}

function formatStatusFilter(value, status) {
    if (value === null || (Array.isArray(value) && value?.length === 0)) {
        return `${status}: {_eq: ""}`;
    }

    if (Array.isArray(value) && value.length > 0) {
        const valuesWithoutNoStatus = value.filter((v) => v !== "noStatus");
        if (valuesWithoutNoStatus.length > 0) {
            const valuesString = valuesWithoutNoStatus
                .map((v) => `"${v}"`)
                .join(",");
            if (value.includes("noStatus")) {
                // Includes 'noStatus' and other statuses
                return `{ _or: [ {auditInfo: {${status}: {_in: [${valuesString}]}}}, {auditInfo: {${status}: {_eq: ""}}} ], }`;
            } else {
                // Does not include 'noStatus'
                return `${status}: { _in: [${valuesString}] }`;
            }
        } else if (value.includes("noStatus")) {
            // Only includes 'noStatus'
            return `{ auditInfo: {
                    _or: [
                        {${status}: {_is_null: true}},
                        {${status}: {_eq: ""}}
                    ]},
              }`;
        }
    }

    return value ? `${status}: { _ilike: "${value}" },` : "";
}

function buildDynamicAuditInfo(auditorStatus, managerStatus, finalDisposition) {
  let auditInfoParts = [];
  let orConditions = [];

  // Helper function to build _or block if "noStatus" is present, otherwise _in block
  function buildStatusFilter(statusArray, statusField) {
    if (Array.isArray(statusArray) && statusArray.length > 0) {
      if (statusArray.includes("noStatus")) {
        // Build _or block for noStatus condition
        return `_or: [
          { ${statusField}: { _is_null: true } },
          { ${statusField}: { _eq: "" } }
        ]`;
      } else {
        // Build _in block for other valid values
        const valuesString = statusArray.map((value) => `"${value}"`).join(", ");
        return `${statusField}: { _in: [${valuesString}] }`;
      }
    }
    return ""; // Return empty string if no valid input
  }

  // Add auditor_status if valid input is present
  if (auditorStatus && Array.isArray(auditorStatus) && auditorStatus.length > 0) {
    const auditorStatusFilter = buildStatusFilter(auditorStatus, "auditor_status");
    if (auditorStatusFilter) {
      auditInfoParts.push(auditorStatusFilter);
    }
  }

  // Add manager_status if valid input is present
  if (managerStatus && Array.isArray(managerStatus) && managerStatus.length > 0) {
    const managerStatusFilter = buildStatusFilter(managerStatus, "manager_status");
    if (managerStatus.includes("noStatus")) {
      orConditions.push(managerStatusFilter);
    } else if (managerStatusFilter) {
      auditInfoParts.push(managerStatusFilter);
    }
  }

  // Add final_disposition if valid input is present
  if (finalDisposition && Array.isArray(finalDisposition) && finalDisposition.length > 0) {
    const finalDispositionFilter = buildStatusFilter(finalDisposition, "final_disposition");
    if (finalDisposition.includes("noStatus")) {
      orConditions.push(finalDispositionFilter);
    } else if (finalDispositionFilter) {
      auditInfoParts.push(finalDispositionFilter);
    }
  }

  // If there are multiple _or conditions, wrap them inside an _and block
  if (orConditions.length > 1) {
    auditInfoParts.push(`_and: [${orConditions.map(cond => `{ ${cond} }`).join(",\n    ")}]`);
  } else if (orConditions.length === 1) {
    // If there is only one _or block, add it directly
    auditInfoParts.push(orConditions[0]);
  }

  // Combine all parts and return the auditInfo block
  return `auditInfo: {
    ${auditInfoParts.join(",\n    ")}
  }`;
}
