import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
    GET_AGENT_AUDIT_DATA,
    GET_LEADS_AND_SALES,
    GET_LEADS_AND_SALES_COUNT,
    GET_LEADS_AND_SALES_LIGHTER,
} from "../graphql/queries";
import {HuddleLeadsTable} from "./components/HuddleLeadsTable";
import {HuddleSalesTable} from "./components/HuddleSalesTable";
import "./RecordPageContainer.css";
import {
    Accordion,
    Box,
    Grid,
    GridItem,
    Card,
    CardBody,
} from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import AgentAccordionItem from "./components/AgentAccordionItem";
import TransactionAccordionItem from "./components/TransactionAccordionItem";
import FinalResultsAccordionItem from "./components/FinalResultsAccordionItem";
import StickyBar from "./components/StickyBar";
import { displayValues } from "../utils";

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/prop-types */
const RecordPageContainer = ({ auditRecord, auditInfo }) => {
    const [salesData, setSalesData] = useState([]);
    const [leadsData, setLeadsData] = useState([]);
    const [useLighterQuery, setUseLighterQuery] = useState(false);

    const {
        loading: agentAuditDataLoading,
        error: agentAuditDataError,
        data: agentRecordData,
    } = useQuery(GET_AGENT_AUDIT_DATA, {
        variables: {
            agent_huddle3_contact_id: auditRecord?.agent_huddle3_contact_id,
        },
        skip: auditRecord == undefined,
    });

    const agentAuditData =
        agentRecordData?.read_only_all_agents?.length > 0
            ? agentRecordData?.read_only_all_agents[0]
            : null;

    if (agentAuditDataError) {
        console.log(agentAuditDataError);
    }

    const leadsAndSalesCountQuery = GET_LEADS_AND_SALES_COUNT(
        auditRecord?.agent_team
            ? `where: {agent_warehouse_generated_team: {_eq: "${auditRecord?.agent_team}"}}`
            : `where: {agent_huddle3_contact_id: {_eq: "${auditRecord?.agent_huddle3_contact_id}"}}`
    );

    const {
        loading: salesAndLeadsCountInfoLoading,
        error: salesAndLeadsCountInfoError,
        data: salesAndLeadsCountInfoData,
    } = useQuery(leadsAndSalesCountQuery);

    if (salesAndLeadsCountInfoError) {
        console.log("Error", salesAndLeadsCountInfoError);
    }
    
    useEffect(() => {
        if (
            !salesAndLeadsCountInfoLoading &&
            salesAndLeadsCountInfoData &&
            salesAndLeadsCountInfoData.read_only_all_agents
        ) {
            let leadCount = 0;
            let saleCount = 0;

            salesAndLeadsCountInfoData.read_only_all_agents.forEach((agent) => {
                saleCount += agent.sales_aggregate.aggregate
                    ? agent.sales_aggregate.aggregate.count
                    : 0;
                leadCount += agent.leads_aggregate.aggregate
                    ? agent.leads_aggregate.aggregate.count
                    : 0;
            });

            // Toggle whether to use the lighter query or not
            if (leadCount < 2000 && saleCount < 2000) {
                setUseLighterQuery(false); // Use the lighter query
            } else {
                setUseLighterQuery(true); // Use the full query
            }
        }
    }, [salesAndLeadsCountInfoData, salesAndLeadsCountInfoLoading]);

    const leadsAndSalesInfoQuery = useLighterQuery
        ? GET_LEADS_AND_SALES_LIGHTER(
              auditRecord?.agent_team
                  ? `where: {agent_warehouse_generated_team: {_eq: "${auditRecord?.agent_team}"}}`
                  : `where: {agent_huddle3_contact_id: {_eq: "${auditRecord?.agent_huddle3_contact_id}"}}`
          )
        : GET_LEADS_AND_SALES(
              auditRecord?.agent_team
                  ? `where: {agent_warehouse_generated_team: {_eq: "${auditRecord?.agent_team}"}}`
                  : `where: {agent_huddle3_contact_id: {_eq: "${auditRecord?.agent_huddle3_contact_id}"}}`
          );

    const {
        data: salesAndLeadsData,
        loading: salesAndLeadsLoading,
        error: salesAndLeadsError,
    } = useQuery(leadsAndSalesInfoQuery);

    useEffect(() => {
        if (
            !salesAndLeadsLoading &&
            salesAndLeadsData &&
            salesAndLeadsData.read_only_all_agents
        ) {
            const { allSales, allLeads } =
                salesAndLeadsData.read_only_all_agents.reduce(
                    (accumulator, agent) => {
                        let saleOppIDs = new Set();
                        let leadOppIDs = new Set();

                        // Process sales
                        for (let sale of agent.sales) {
                            if (
                                !accumulator.opportunityIds.has(
                                    sale.opportunity_id
                                ) &&
                                !saleOppIDs.has(sale.opportunity_id)
                            ) {
                                let index = accumulator.allSales.findIndex(
                                    (s) =>
                                        s.opportunity_name >
                                        sale.opportunity_name
                                );
                                if (index === -1) {
                                    accumulator.allSales.push(sale);
                                } else {
                                    accumulator.allSales.splice(index, 0, sale);
                                }
                                saleOppIDs.add(sale.opportunity_id);
                            }
                        }

                        // Process leads
                        for (let lead of agent.leads) {
                            if (
                                !accumulator.opportunityIds.has(
                                    lead.opportunity_id
                                ) &&
                                !leadOppIDs.has(lead.opportunity_id)
                            ) {
                                let index = accumulator.allLeads.findIndex(
                                    (l) =>
                                        l.opportunity_name >
                                        lead.opportunity_name
                                );
                                if (index === -1) {
                                    accumulator.allLeads.push(lead);
                                } else {
                                    accumulator.allLeads.splice(index, 0, lead);
                                }
                                leadOppIDs.add(lead.opportunity_id);
                            }
                        }

                        // Update accumulator
                        accumulator.opportunityIds = new Set([
                            ...accumulator.opportunityIds,
                            ...saleOppIDs,
                            ...leadOppIDs,
                        ]);
                        return accumulator;
                    },
                    { allSales: [], allLeads: [], opportunityIds: new Set() }
                );

            setSalesData(allSales);
            setLeadsData(allLeads);
        }
    }, [salesAndLeadsData, salesAndLeadsLoading]);

    if (salesAndLeadsError) {
        console.log("Error", salesAndLeadsError);
    }

    const transactionSide =
        displayValues[auditRecord?.mls_agent_transaction_side] || "";

    if (!agentAuditDataLoading && auditRecord) {
        return (
            <ChakraProvider>
                <span
                    style={{
                        textAlign: "end",
                        fontWeight: "bold",
                        fontSize: "10px",
                    }}
                >
                    Last Modified Date: {auditInfo?.last_modified_datetime}
                </span>
                <Box
                    w="100%"
                    style={{ paddingTop: "0px" }}
                    p={4}
                    textAlign="center"
                >
                    <StickyBar
                        agentInfo={agentAuditData}
                        auditRecord={auditRecord}
                    ></StickyBar>
                    <Accordion allowMultiple defaultIndex={[1, 2]}>
                        <AgentAccordionItem
                            loading={agentAuditDataLoading}
                            agentInfo={agentAuditData}
                            auditRecord={auditRecord}
                        />
                        <TransactionAccordionItem
                            auditInfo={auditInfo}
                            auditRecord={auditRecord}
                        />
                        <FinalResultsAccordionItem
                            auditInfo={auditInfo}
                            auditRecord={auditRecord}
                        />
                    </Accordion>
                    <div style={{ width: "100%", overflowX: "auto" }}> 
                        <Grid templateColumns="repeat(2, 1fr)" maxWidth="100vw">
                            <GridItem>
                                <Card>
                                    <CardBody>
                                        <HuddleSalesTable
                                                    salesData={salesData}
                                                    sale_selected_id={auditInfo?.sale_id}
                                                    agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                                                    parcel_surrogate_unique_id={auditRecord?.parcel_surrogate_unique_id}
                                                    parcel_transaction_unique_id={auditRecord?.parcel_transaction_unique_id}
                                                    listing_id={auditRecord?.mls_closed_listing_id}
                                                    transaction_name={transactionSide == "Buyer" ? auditRecord?.transaction_buyer : auditRecord?.transaction_seller}
                                                    isLight={useLighterQuery}
                                                ></HuddleSalesTable>
                                    </CardBody>
                                </Card>
                            </GridItem>
                            <GridItem>
                                <Card>
                                    <CardBody>
                                        <HuddleLeadsTable
                                                    leadsData={leadsData}
                                                    confirmed_lead_id={auditInfo?.confirmed_lead_id}
                                                    potential_lead_ids={auditInfo?.potential_lead_ids}
                                                    agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                                                    parcel_surrogate_unique_id={auditRecord?.parcel_surrogate_unique_id}
                                                    parcel_transaction_unique_id={auditRecord?.parcel_transaction_unique_id}
                                                    listing_id={auditRecord?.mls_closed_listing_id}
                                                    mls_closing_date={auditRecord?.listing_close_date}
                                                    transaction_name={transactionSide == "Buyer" ? auditRecord?.transaction_buyer : auditRecord?.transaction_seller}
                                                    isLight={useLighterQuery}
                                                ></HuddleLeadsTable>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </Grid>
                    </div>
                </Box>
            </ChakraProvider>
        );
    } else {
        return <div>Loading...</div>;
    }
};

export default RecordPageContainer;
