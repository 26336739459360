import React from "react";
import { useSubscription, useQuery } from "@apollo/client";
import {
  NOTIFY_AUDIT_INFO_BY_ID,
} from "../graphql/subscriptions"
import { GET_AGENT_AUDIT_RECORD } from "../graphql/queries";
import { useParams } from "react-router-dom";
import RecordPageContainer from "./RecordPageContainer";

/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
export const AuditRecordPage = () => {
  let params = useParams();

  if (params == undefined || params.listing_id == undefined) {
    return <div>No Parameters added</div>;
  }

  const {
    loading: recordLoading,
    error: recordError,
    data,
  } = useSubscription(NOTIFY_AUDIT_INFO_BY_ID, {
    variables: {
      listingId: params.listing_id,
      agentContactId: params.agent_huddle3_contact_id,
      parcelTransactionID: params.parcel_transaction_unique_id,
      parcelSurrogateID: params.parcel_surrogate_unique_id
    },
  });

  const {
    loading: auditRecordLoading,
    error: auditRecordError,
    data: auditRecordData,
  } = useQuery(GET_AGENT_AUDIT_RECORD, {
    variables: {
      listing_Id: params.listing_id,
      agentContactId: params.agent_huddle3_contact_id,
      parcelTransactionID: params.parcel_transaction_unique_id,
      parcelSurrogateID: params.parcel_surrogate_unique_id
    },
  });

  let auditInfo = data?.audit_process_audit_info[0];
  let auditRecord =
    auditRecordData
      ?.read_only_agent_auditor_listings_transactions_view[0];


  if (recordError || auditRecordError) {
    console.log(recordError);
    console.log(auditRecordError);
  }

  if (!recordLoading && !auditRecordLoading){
      return(<RecordPageContainer auditRecord={auditRecord} auditInfo={auditInfo}></RecordPageContainer>)
  }
};
export default AuditRecordPage;
