import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    StatUpArrow,
    StatDownArrow,
} from "@chakra-ui/react";
import { UPDATE_MATCHED_ID } from "../../graphql/mutations";
import Fuse from "fuse.js";
import PropTypes from "prop-types";

/* eslint-disable no-unused-vars */
export const HuddleSalesTable = ({
    salesData,
    agent_huddle3_contact_id,
    listing_id,
    sale_selected_id,
    parcel_surrogate_unique_id,
    parcel_transaction_unique_id,
    transaction_name,
    isLight,
}) => {
    const baseUrl =
        "https://huddle3.lightning.force.com/lightning/r/Opportunity";

    const [mutateSaleId] = useMutation(UPDATE_MATCHED_ID);

    const [selectedValue, setSelectedValue] = useState("");
    const [sortedSalesData, setSortedSalesData] = useState([]);
    const [sortDirection, setSortDirection] = useState("asc");
    const [selectedFilter, setSelectedFilter] = useState("");

    useEffect(() => {
        if (salesData) {
            const fuse = new Fuse(salesData, {
                includeScore: true,
                keys: ["opportunity_name"],
                includeMatches: true,
                findAllMatches: true,
                shouldSort: true,
            });

            if (transaction_name) {
                const transactionNames = transaction_name.split(",");
                const matchedRecords = transactionNames.flatMap((name) => {
                    const result = fuse.search(name);
                    return result;
                });

                const sortedMatchedRecords = matchedRecords
                    .sort((a, b) => a.score - b.score)
                    .map(({ item }) => item);

                const unmatchedRecords = salesData.filter(
                    (record) => !sortedMatchedRecords.includes(record)
                );
                setSortedSalesData([
                    ...sortedMatchedRecords,
                    ...unmatchedRecords,
                ]);
            } else {
                setSortedSalesData(salesData);
            }
        }
    }, [salesData, transaction_name]);

    function handleRadio(event) {
        setSelectedValue(event.target.value);
        if (
            listing_id &&
            parcel_surrogate_unique_id &&
            parcel_transaction_unique_id &&
            event.target.value != "clear"
        ) {
            mutateSaleId({
                variables: {
                    agent_huddle3_contact_id: agent_huddle3_contact_id,
                    listing_id: listing_id,
                    sale_id: event.target.value,
                    parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                    parcel_transaction_unique_id: parcel_transaction_unique_id,
                    confirmed_lead_id: "",
                },
            });
        } else if (event.target.value == "clear") {
            mutateSaleId({
                variables: {
                    agent_huddle3_contact_id: agent_huddle3_contact_id,
                    listing_id: listing_id,
                    sale_id: null,
                    parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                    parcel_transaction_unique_id: parcel_transaction_unique_id,
                    confirmed_lead_id: null,
                },
            });
        }
    }

    function sortByCloseDate(newSortDirection) {
        const sortedData = [...sortedSalesData].sort((a, b) => {
            if (
                a?.estimated_closing_date != null &&
                b?.estimated_closing_date != null
            ) {
                const createdDateA = new Date(a?.estimated_closing_date);
                const createdDateB = new Date(b?.estimated_closing_date);

                if (newSortDirection === "asc") {
                    return createdDateA - createdDateB;
                } else {
                    return createdDateB - createdDateA;
                }
            }
        });
        setSelectedFilter("closeDate" + newSortDirection);
        setSortedSalesData(sortedData);
    }

    function sortByFirstName(newSortDirection) {
        const sortedData = [...sortedSalesData].sort((a, b) => {
            if (newSortDirection === "asc") {
                return a.opportunity_first_name.localeCompare(
                    b.opportunity_first_name
                );
            } else {
                return b.opportunity_first_name.localeCompare(
                    a.opportunity_first_name
                );
            }
        });
        setSelectedFilter("firstName" + newSortDirection);
        setSortedSalesData(sortedData);
    }

    function sortByLastName(newSortDirection) {
        const sortedData = [...sortedSalesData].sort((a, b) => {
            if (newSortDirection === "asc") {
                return a.opportunity_last_name.localeCompare(
                    b.opportunity_last_name
                );
            } else {
                return b.opportunity_last_name.localeCompare(
                    a.opportunity_last_name
                );
            }
        });
        setSelectedFilter("lastName" + newSortDirection);
        setSortedSalesData(sortedData);
    }

    function handleSort(event) {
        if (event?.target?.id != undefined) {
            const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
            switch (event.target.id) {
                case "closeDate":
                    sortByCloseDate(newSortDirection);
                    break;
                case "firstName":
                    sortByFirstName(newSortDirection);
                    break;
                case "lastName":
                    sortByLastName(newSortDirection);
                    break;
                default:
                    break;
            }
            setSortDirection(newSortDirection);
        }
    }

    return (
        <TableContainer>
            <Table size="sm" variant="striped" colorScheme="whiteAlpha">
                <TableCaption
                    overflowX="unset"
                    overflowY="unset"
                    placement="top"
                >
                    Huddle Sales
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>Sale Summary</Th>
                        {!isLight && (
                            <Th
                                id="firstName"
                                onClick={handleSort}
                                style={{ cursor: "pointer" }}
                            >
                                First Name
                                {selectedFilter.includes("firstName") ? (
                                    selectedFilter.includes("asc") ? (
                                        <StatUpArrow />
                                    ) : (
                                        <StatDownArrow />
                                    )
                                ) : (
                                    " "
                                )}
                            </Th>
                        )}
                        {!isLight && (
                            <Th
                                id="lastName"
                                onClick={handleSort}
                                style={{ cursor: "pointer" }}
                            >
                                Last Name
                                {selectedFilter.includes("lastName") ? (
                                    selectedFilter.includes("asc") ? (
                                        <StatUpArrow />
                                    ) : (
                                        <StatDownArrow />
                                    )
                                ) : (
                                    " "
                                )}
                            </Th>
                        )}
                        {!isLight && (
                            <Th
                                id="closeDate"
                                onClick={handleSort}
                                style={{ cursor: "pointer" }}
                            >
                                Close Date
                                {selectedFilter.includes("closeDate") ? (
                                    selectedFilter.includes("asc") ? (
                                        <StatUpArrow />
                                    ) : (
                                        <StatDownArrow />
                                    )
                                ) : (
                                    " "
                                )}
                            </Th>
                        )}
                        <Th>Address</Th>
                        <Th>
                            Match{" "}
                            <input
                                type="radio"
                                name="clear"
                                checked={sale_selected_id == null}
                                value={"clear"}
                                onChange={handleRadio}
                            />
                        </Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {sortedSalesData.map((sale, index) => {
                        const opportunityUrl = `${baseUrl}/${sale.opportunity_id}/view`;
                        return (
                            <Tr key={index}>
                                <Td>
                                    <a
                                        style={{ color: "blue" }}
                                        href={opportunityUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {sale.opportunity_name}
                                    </a>
                                </Td>
                                {!isLight && (
                                    <Td style={{ maxWidth: "20px" }}>
                                        {sale.opportunity_first_name}
                                    </Td>
                                )}
                                {!isLight && (
                                    <Td style={{ maxWidth: "20px" }}>
                                        {sale.opportunity_last_name}
                                    </Td>
                                )}
                                {!isLight && (
                                    <Td>{sale.estimated_closing_date}</Td>
                                )}
                                <Td>{sale.property_address}</Td>
                                <Td>
                                    <input
                                        key={sale.opportunity_id}
                                        type="radio"
                                        name="match"
                                        checked={
                                            sale.opportunity_id ==
                                            sale_selected_id
                                        }
                                        value={sale.opportunity_id}
                                        onChange={handleRadio}
                                    />
                                </Td>
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

HuddleSalesTable.propTypes = {
    salesData: PropTypes.arrayOf(
        PropTypes.shape({
            opportunity_id: PropTypes.string,
            opportunity_name: PropTypes.string,
            opportunity_first_name: PropTypes.string,
            opportunity_last_name: PropTypes.string,
            estimated_closing_date: PropTypes.string,
            property_address: PropTypes.string,
        })
    ),
    agent_huddle3_contact_id: PropTypes.string,
    listing_id: PropTypes.number,
    sale_selected_id: PropTypes.string,
    parcel_surrogate_unique_id: PropTypes.string,
    parcel_transaction_unique_id: PropTypes.string,
    transaction_name: PropTypes.string,
    isLight: PropTypes.bool,
};

export default HuddleSalesTable;
