import React, { useState, useContext, useEffect } from "react";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import BlankTableComponent from "./subcomponents/BlankTableComponent";
import { appContext } from "../AppProvider";
import { CSVLink } from "react-csv";
import { ClipLoader } from "react-spinners";
import { useQuery } from "@apollo/client";
import { GET_NO_STATUS_REPORT } from "../graphql/queries";
import { columns } from "./subcomponents/GroupedRecordsTableColumns";
import Pagination from "./subcomponents/Pagination";

const GroupedRecordsTable = () => {
    const {
        groupedRecordsData,
        groupedRecordsLoading,
        groupedRecordCountLoading,
        totalGroupedCount,
        setGroupedRecordOffset,
    } = useContext(appContext);

    const {
        loading: groupedNoStatusRecordsLoading,
        data: groupedNoStatusRecordsData,
    } = useQuery(GET_NO_STATUS_REPORT);

    const [data, setData] = useState([]);
    const [exportData, setExportData] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = totalGroupedCount
        ? Math.ceil(totalGroupedCount / 30)
        : 1;

    useEffect(() => {
        if (groupedRecordsLoading && !groupedRecordsData) {
            setData([]);
        } else {
            setData(groupedRecordsData);
        }
    }, [groupedRecordsData, groupedRecordsLoading]);

    useEffect(() => {
        if (!groupedNoStatusRecordsLoading && groupedNoStatusRecordsData) {
            // Map over the data to format it for CSV export
            const formattedData =
                groupedNoStatusRecordsData.read_only_all_agents.map(
                    (record) => ({
                        "Agent Name": record.agent_name,
                        "Agent Team": record.agent_warehouse_generated_team,
                        "Number of Records":
                            record.transactions_aggregate.aggregate.count,
                    })
                );

            setExportData(formattedData);
        }
    }, [groupedNoStatusRecordsData, groupedNoStatusRecordsLoading]);

    const table = useReactTable({
        data: data || [],
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setGroupedRecordOffset((page - 1) * 30);
    };

    const getCurrentDate = () => {
        const date = new Date();
        return date.toISOString().split("T")[0];
    };

    return !groupedRecordsLoading ? (
        <div>
            <div className="table-title-component">
                <h4 className="table-title-header">Grouped by Agent</h4>
                
                <div>
                    {!groupedNoStatusRecordsLoading &&
                    groupedNoStatusRecordsData ? (
                        <CSVLink
                            data={exportData}
                            id="export-data"
                            filename={`No_Status_Records_By_Agents_${getCurrentDate()}.csv`}
                        >
                            Export No Status Report
                        </CSVLink>
                    ) : (
                        <div>
                            Loading...{" "}
                            <ClipLoader
                                size={24}
                                color={"#1fa02f"}
                                loading={true}
                            />
                        </div>
                    )}
                </div>
            </div>
            <BlankTableComponent table={table} />
            <div
                style={{
                    textAlign: "end",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
                <div>
                    <span>
                        Page {currentPage} of{" "}
                        {isNaN(totalPages) ? 1 : totalPages}
                    </span>
                    <span style={{ display: "block", color: "grey" }}>
                        Showing{" "}
                        <span
                            style={{ display: "contents", fontWeight: "bold" }}
                        >
                            {groupedRecordsData?.length}
                        </span>{" "}
                        Records
                    </span>
                    <span style={{ display: "contents", fontWeight: "bold" }}>
                        Total Records:
                    </span>{" "}
                    {!groupedRecordCountLoading ? totalGroupedCount : 0}
                </div>
            </div>
        </div>
    ) : (
        <span> Loading... </span>
    );
};

export default GroupedRecordsTable;
