import {AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Grid, GridItem} from "@chakra-ui/react";
import FinalResultStatus from "./subcomponent/FinalResultStatus";
import TextAreaCustom from "./subcomponent/TextAreaCustom";
import React from "react";
import PropTypes from "prop-types";


const FinalResultsAccordionItem = ({auditInfo, auditRecord}) => {
    

    return (
        <AccordionItem>
            <h2>
                <AccordionButton color="white" bg="#18a02f">
                    <Box
                        as="span"
                        flex="1"
                        textAlign="center"
                        fontWeight="bold"
                    >
                        Final Results
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel>
                <Grid
                    gap={4}
                    templateColumns="repeat(1, 1fr)"
                    templateRows="repeat(2,1fr)"
                >
                    <GridItem colStart={1} colSpan={1}>
                        <Grid templateColumns="repeat(5, 1fr)">
                            <GridItem
                                style={{display: "flex", alignItems: "center"}}
                            >
                                <FinalResultStatus
                                    name="Auditor Status"
                                    text={auditInfo?.auditor_status}
                                    listing_id={auditRecord?.mls_closed_listing_id}
                                    placeholder="Status"
                                    agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                                    parcel_surrogate_unique_id={
                                        auditRecord?.parcel_surrogate_unique_id
                                    }
                                    parcel_transaction_unique_id={
                                        auditRecord?.parcel_transaction_unique_id
                                    }
                                    audit_record_unique_id={auditRecord?.audit_record_unique_id}
                                    type="auditor_status"
                                ></FinalResultStatus>
                            </GridItem>
                            <GridItem colSpan={4}>
                                <TextAreaCustom
                                    id="auditor-status"
                                    text={auditInfo?.auditor_notes}
                                    placeholder="Auditor Notes"
                                    type="auditor_notes"
                                    agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                                    parcel_surrogate_unique_id={
                                        auditRecord?.parcel_surrogate_unique_id
                                    }
                                    parcel_transaction_unique_id={
                                        auditRecord?.parcel_transaction_unique_id
                                    }
                                    listing_id={auditRecord?.mls_closed_listing_id}
                                    last_modified_datetime={auditInfo?.auditor_status_last_modified}
                                    last_modified_by={auditInfo?.auditor_status_last_modified_by}
                                    audit_record_unique_id={auditRecord?.audit_record_unique_id}
                                ></TextAreaCustom>
                            </GridItem>
                        </Grid>
                    </GridItem>
                    <GridItem>
                        <Grid templateColumns="repeat(5, 1fr)">
                            <GridItem
                                style={{display: "flex", alignItems: "center"}}
                            >
                                <FinalResultStatus
                                    name="Manager Status"
                                    text={auditInfo?.manager_status}
                                    listing_id={auditRecord?.mls_closed_listing_id}
                                    placeholder="Status"
                                    agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                                    parcel_surrogate_unique_id={
                                        auditRecord?.parcel_surrogate_unique_id
                                    }
                                    parcel_transaction_unique_id={
                                        auditRecord?.parcel_transaction_unique_id
                                    }
                                    audit_record_unique_id={auditRecord?.audit_record_unique_id}
                                    type="manager_status"
                                ></FinalResultStatus>
                            </GridItem>
                            <GridItem colSpan={4}>
                                <TextAreaCustom
                                    id="manager-status"
                                    text={auditInfo?.manager_notes}
                                    placeholder="Manager Notes"
                                    type="manager_notes"
                                    agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                                    parcel_surrogate_unique_id={
                                        auditRecord?.parcel_surrogate_unique_id
                                    }
                                    parcel_transaction_unique_id={
                                        auditRecord?.parcel_transaction_unique_id
                                    }
                                    listing_id={auditRecord?.mls_closed_listing_id}
                                    last_modified_datetime={auditInfo?.manager_status_last_modified}
                                    last_modified_by={auditInfo?.manager_status_last_modified_by}
                                    status_escalation_date={auditInfo?.status_escalation_datetime}
                                    audit_record_unique_id={auditRecord?.audit_record_unique_id}
                                ></TextAreaCustom>
                            </GridItem>
                        </Grid>
                    </GridItem>
                    <GridItem>
                        <Grid templateColumns="repeat(5, 1fr)">
                            <GridItem style={{alignItems: "center"}}>
                                <FinalResultStatus
                                    name="Final Disposition"
                                    text={auditInfo?.final_disposition}
                                    listing_id={auditRecord?.mls_closed_listing_id}
                                    agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                                    parcel_surrogate_unique_id={
                                        auditRecord?.parcel_surrogate_unique_id
                                    }
                                    parcel_transaction_unique_id={
                                        auditRecord?.parcel_transaction_unique_id
                                    }
                                    audit_record_unique_id={auditRecord?.audit_record_unique_id}
                                    placeholder="Status"
                                    type="final_disposition"
                                ></FinalResultStatus>
                            </GridItem>
                            <GridItem colSpan={4}>
                                <TextAreaCustom
                                    id="final-disposition"
                                    text={auditInfo?.mail_merge_text}
                                    placeholder="Final Disposition Notes"
                                    type="mail_merge_text"
                                    agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                                    parcel_surrogate_unique_id={
                                        auditRecord?.parcel_surrogate_unique_id
                                    }
                                    parcel_transaction_unique_id={
                                        auditRecord?.parcel_transaction_unique_id
                                    }
                                    listing_id={auditRecord?.mls_closed_listing_id}
                                    last_modified_datetime={auditInfo?.final_disposition_last_modified}
                                    last_modified_by={auditInfo?.final_disposition_last_modified_by}
                                    audit_record_unique_id={auditRecord?.audit_record_unique_id}
                                ></TextAreaCustom>
                            </GridItem>
                        </Grid>
                    </GridItem>
                </Grid>
            </AccordionPanel>
        </AccordionItem>
    )
}

FinalResultsAccordionItem.propTypes = {
    auditInfo: PropTypes.object,
    auditRecord: PropTypes.object
}

export default FinalResultsAccordionItem;
