import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  UPDATE_AUDITOR_NOTES,
  UPDATE_MANAGER_NOTES,
  UPDATE_MAIL_MERGE_TEXT,
} from "../../../graphql/mutations";
import { Spinner } from "@chakra-ui/react";
import PropTypes from 'prop-types';
import { useUserProfile } from "../../../oktaUtils";

export const TextAreaCustom = ({
  text,
  listing_id,
  placeholder,
  type,
  parcel_surrogate_unique_id,
  parcel_transaction_unique_id,
  agent_huddle3_contact_id,
  last_modified_datetime,
  status_escalation_date,
  last_modified_by,
  audit_record_unique_id,
}) => {
  const [inputValue, setInputValue] = useState(text);
  const [mutateAuditorNotes] = useMutation(UPDATE_AUDITOR_NOTES);
  const [mutateManagerNotes] = useMutation(UPDATE_MANAGER_NOTES);
  const [mutateMailMergeText] = useMutation(UPDATE_MAIL_MERGE_TEXT);
  const [isTyping, setIsTyping] = useState(false);
  const [isMutationFinished, setIsMutationFinished] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { userInfo, isLoading, error } = useUserProfile();
  const [ userName, setUserName ] = useState(null);

  useEffect(() => {
    if (!isLoading && !error) {
      if (userInfo?.name) {
        setUserName(userInfo.name);
      }
    } else {
      setUserName(null);
    }
  }, [userInfo, isLoading, error]);

  useEffect(() => {
    const performMutation = async () => {
      try {
        switch (type) {
          case "auditor_notes":
            if (
              listing_id &&
              parcel_surrogate_unique_id &&
              parcel_transaction_unique_id &&
              inputValue != text
            ) {
              await mutateAuditorNotes({
                variables: {
                  listing_id: listing_id,
                  auditor_notes: inputValue,
                  parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                  parcel_transaction_unique_id: parcel_transaction_unique_id,
                  agent_huddle3_contact_id: agent_huddle3_contact_id,
                  audit_record_unique_id: audit_record_unique_id,
                  auditor_status_last_modified_by: userName
                },
              });
              setIsTyping(false);
              setIsMutationFinished(true);
            }
            break;
          case "manager_notes":
            if (
              listing_id &&
              parcel_surrogate_unique_id &&
              parcel_transaction_unique_id &&
              inputValue != text
            ) {
              await mutateManagerNotes({
                variables: {
                  listing_id: listing_id,
                  manager_notes: inputValue,
                  parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                  parcel_transaction_unique_id: parcel_transaction_unique_id,
                  agent_huddle3_contact_id: agent_huddle3_contact_id,
                  audit_record_unique_id: audit_record_unique_id,
                  manager_status_last_modified_by: userName
                },
              });
              setIsTyping(false);
              setIsMutationFinished(true);
            }
            break;
          case "mail_merge_text":
            if (
              listing_id &&
              parcel_surrogate_unique_id &&
              parcel_transaction_unique_id &&
              inputValue != text
            ) {
              await mutateMailMergeText({
                variables: {
                  listing_id: listing_id,
                  mail_merge_text: inputValue,
                  parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                  parcel_transaction_unique_id: parcel_transaction_unique_id,
                  agent_huddle3_contact_id: agent_huddle3_contact_id,
                  audit_record_unique_id: audit_record_unique_id,
                  final_disposition_last_modified_by: userName
                },
              });
              setIsTyping(false);
              setIsMutationFinished(true);
            }
            break;
          default:
            break;
        }
      } catch (error) {
        console.error("Mutation error:", error);
        setErrorMessage("Error occurred while saving: " + error.message);
      }
    };

    performMutation();

    setInputValue(inputValue);
    if (isMutationFinished) {
      setTimeout(() => {
        setIsMutationFinished(false);
        setErrorMessage(null);
      }, 5000);
    }
  }, [inputValue, isMutationFinished]);

  const onInputChange = (value) => {
    setInputValue(value);
  };

  const showIsTyping = () => {
    setIsTyping(true);
  };

  return (
    <div>
      {isTyping ? (
        <div style={{textAlign: 'end'}}>
          Saving
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            style={{ marginLeft: '10px',float: "right" }}
          />
        </div>
      ) : isMutationFinished ? (
        <div style={{textAlign: 'end'}}>
          Saved ✅
        </div>
      )
      : null}

      {errorMessage && (
        <div style={{ textAlign: "end", color: "red" }}>{errorMessage}</div>
      )}

      <textarea
        style={{
          width: "-webkit-fill-available",
          height: "10vh",
          maxHeight: "10vh",
          border: "1px solid gray",
          borderRadius: "4px",
        }}
        key={text}
        type="text"
        placeholder={placeholder}
        defaultValue={text}
        onChange={showIsTyping}
        onBlur={(e) => onInputChange(e.target.value)}
      />
      <div style={{ display: 'flex', flexDirection: "column", alignItems: "flex-end" }}>
        {last_modified_datetime ? (
          <label style={{ fontSize: "8px" }}>
            Last Modified Datetime:{" "}
            {new Date(last_modified_datetime).toISOString()}
          </label>
        ) : null}
        {last_modified_by ? (
          <label style={{ fontSize: "8px" }}>
            Last Modified By:{" "}
            {last_modified_by}
          </label>
        ) : null}
        {status_escalation_date ? (
          <label style={{ fontSize: "8px" }}>
            Status Escalation  Datetime:{" "}
            {new Date(status_escalation_date).toISOString()}
          </label>
        ) : null}
      </div>
    </div>
  );
};

TextAreaCustom.propTypes = {
  text: PropTypes.string,
  listing_id: PropTypes.number,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['auditor_notes', 'manager_notes', 'mail_merge_text']),
  parcel_surrogate_unique_id: PropTypes.string,
  parcel_transaction_unique_id: PropTypes.string,
  agent_huddle3_contact_id: PropTypes.string,
  last_modified_datetime: PropTypes.string,
  status_escalation_date: PropTypes.string,
  last_modified_by: PropTypes.string,
  audit_record_unique_id: PropTypes.string,
};


export default TextAreaCustom;
