import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Card, CardBody,
    Grid,
    GridItem
} from "@chakra-ui/react";
import TransactionStatus from "./subcomponent/TransactionStatus";
import {AddressInformation} from "./subcomponent/AddressInformation";
import {PriceInformation} from "./subcomponent/PriceInformation";
import {DateInformation} from "./subcomponent/DateInformation";
import React, { useEffect, useRef } from 'react';
import {displayValues} from "../../utils";
import PropTypes from "prop-types";
import {
    UPDATE_TRANSACTION_BUYER_STATUS,
    UPDATE_TRANSACTION_SELLER_STATUS,
} from "../../graphql/mutations";
import { useMutation } from "@apollo/client";




const TransactionAccordionItem = ({auditInfo, auditRecord}) => {

    const [mutateTransactionBuyerStatus] = useMutation(
        UPDATE_TRANSACTION_BUYER_STATUS
      );
      const [mutateTransactionSellerStatus] = useMutation(
        UPDATE_TRANSACTION_SELLER_STATUS
      );

    const transactionSide = displayValues[auditRecord?.mls_agent_transaction_side] || "";

    const mutationCalled = useRef(false); // Ref to track if the mutation was called

    useEffect(() => {
        // Skip if the mutation was already called or updateMade is true
        if (mutationCalled.current) return;

        if (((auditInfo === null || auditInfo === undefined) || (auditInfo?.transaction_seller_status === "" || auditInfo?.transaction_seller_status === null)) && transactionSide === "Buyer") {
            mutationCalled.current = true; // Mark as called to prevent future executions
            mutateTransactionSellerStatus({
                variables: {
                  agent_huddle3_contact_id: auditRecord?.agent_huddle3_contact_id,
                  listing_id: auditRecord?.mls_closed_listing_id,
                  transaction_seller_status: "Our Agent Didn’t Help This Customer",
                  parcel_surrogate_unique_id: auditRecord?.parcel_surrogate_unique_id,
                  parcel_transaction_unique_id: auditRecord?.parcel_transaction_unique_id,
                },
            });
        } else if (((auditInfo === null || auditInfo === undefined) || (auditInfo?.transaction_buyer_status === "" || auditInfo?.transaction_buyer_status === null)) && transactionSide === "Lister") {
            mutationCalled.current = true; // Mark as called to prevent future executions
            mutateTransactionBuyerStatus({
                variables: {
                  agent_huddle3_contact_id: auditRecord?.agent_huddle3_contact_id,
                  listing_id: auditRecord?.mls_closed_listing_id,
                  transaction_buyer_status: "Our Agent Didn’t Help This Customer",
                  parcel_surrogate_unique_id: auditRecord?.parcel_surrogate_unique_id,
                  parcel_transaction_unique_id: auditRecord?.parcel_transaction_unique_id,
                },
            });
        }
    }, [auditInfo, auditRecord, transactionSide]);

    return (
        <AccordionItem>
            <h2>
                <AccordionButton color="white" bg="#18a02f">
                    <Box
                        as="span"
                        flex="1"
                        textAlign="center"
                        fontWeight="bold"
                    >
                        Listing Transaction & Parcel Information
                    </Box>
                    <AccordionIcon/>
                </AccordionButton>
            </h2>
            <AccordionPanel>
                <Grid templateColumns="repeat(4, 1fr)" gap={4}>
                    <GridItem colSpan={4}>
                        <Card>
                            <CardBody>
                                <div style={{fontWeight: "bold"}}>
                                    <h2>
                                        Transaction Side:
                                        <span
                                            style={{
                                                display: "inline",
                                                textTransform: "capitalize",
                                            }}
                                        >
                                    {" "}
                                            {transactionSide}
                                  </span>
                                    </h2>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem textAlign="center" colSpan={2}>
                        <TransactionStatus
                            name="Transaction Buyer Info"
                            information={auditRecord?.transaction_buyer}
                            text={auditInfo?.transaction_buyer_status}
                            agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                            listing_id={auditRecord?.mls_closed_listing_id}
                            placeholder="Transaction Status"
                            type="transaction_buyer_status"
                            parcel_surrogate_unique_id={
                                auditRecord?.parcel_surrogate_unique_id
                            }
                            parcel_transaction_unique_id={
                                auditRecord?.parcel_transaction_unique_id
                            }
                        ></TransactionStatus>
                    </GridItem>
                    <GridItem textAlign="center" colSpan={2}>
                        <TransactionStatus
                            name="Transaction Seller Info"
                            information={auditRecord?.transaction_seller}
                            text={auditInfo?.transaction_seller_status}
                            agent_huddle3_contact_id={auditRecord?.agent_huddle3_contact_id}
                            listing_id={auditRecord?.mls_closed_listing_id}
                            placeholder="Transaction Status"
                            type="transaction_seller_status"
                            parcel_surrogate_unique_id={
                                auditRecord?.parcel_surrogate_unique_id
                            }
                            parcel_transaction_unique_id={
                                auditRecord?.parcel_transaction_unique_id
                            }
                        ></TransactionStatus>
                    </GridItem>
                </Grid>
                <Grid mt={10} templateColumns="repeat(3, 1fr)" gap={4}>
                    <GridItem textAlign="center">
                        <Card>
                            <CardBody>
                                {!auditRecord ? (
                                    "Loading..."
                                ) : (
                                    <AddressInformation
                                        auditRecord={auditRecord}
                                    ></AddressInformation>
                                )}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem textAlign="center">
                        <Card>
                            <CardBody>
                                {!auditRecord ? (
                                    "Loading..."
                                ) : (
                                    <PriceInformation
                                        auditRecord={auditRecord}
                                    ></PriceInformation>
                                )}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem textAlign="center">
                        <Card>
                            <CardBody>
                                {!auditRecord ? (
                                    "Loading..."
                                ) : (
                                    <DateInformation
                                        auditRecord={auditRecord}
                                    ></DateInformation>
                                )}
                            </CardBody>
                        </Card>
                    </GridItem>
                </Grid>
            </AccordionPanel>
        </AccordionItem>
    )
}

TransactionAccordionItem.propTypes = {
    auditInfo: PropTypes.object,
    auditRecord: PropTypes.object
}

export default TransactionAccordionItem;
