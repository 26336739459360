// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `label {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
}

span {
    display: block;
    overflow: hidden;
    padding: 0px 4px 0px 6px;
}
  
Textarea {
    width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/containers/RecordPageContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,wBAAwB;AAC5B;;AAEA;IACI,UAAU;AACd","sourcesContent":["label {\n    float: left;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\nspan {\n    display: block;\n    overflow: hidden;\n    padding: 0px 4px 0px 6px;\n}\n  \nTextarea {\n    width: 70%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
