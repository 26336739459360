import { gql } from "@apollo/client";

/// Make sure to keep GET_INDIVIDUAL_LISTING_DATA_COUNT filters aligned with this query
export function GET_INDIVIDUAL_LISTINGS_DATA(column, direction, filters) {
  var orderByColumn = "";
  let orderBy = "";
  
  if (column === "transaction_count") {
    orderByColumn = "mls_code";
  } else if (column === "agent_warehouse_generated_team") {
    orderByColumn = "agent_team";
  } else if (column === "proposed_lead_ids") {
    orderByColumn = "proposed_lead_ids";
  } else {
    orderByColumn = column;
  }
  
  if (orderByColumn === "auditor_status") {
    direction = direction === "asc" ? "asc_nulls_first" : "desc_nulls_last";
    orderBy = `{ auditInfo: { auditor_status: ${direction} } }`;
  } else if (orderByColumn === "manager_status") {
    direction = direction === "asc" ? "asc_nulls_first" : "desc_nulls_last";
    orderBy = `{ auditInfo: { manager_status: ${direction} } }`;
  } else if (orderByColumn === "final_disposition") {
    direction = direction === "asc" ? "asc_nulls_first" : "desc_nulls_last";
    orderBy = `{ auditInfo: { final_disposition: ${direction} } }`;
  } else {
    orderBy = `{ ${orderByColumn}: ${direction} }`;
  }

  return gql`
  subscription getIndividualListingsData(
            $offset: Int
            $limit: Int
        ) {
            read_only_agent_auditor_listings_transactions_view(
                offset: $offset
                limit: $limit
                order_by: [${orderBy}]
                where: {
                ${filters.mlsQueryPart}
                ${filters.agentQueryPart}
                ${filters.individualTeamQueryPart}
                ${filters.individualAuditInfoQueryPart}
                ${filters.noPublicInfoFilterPart}
                ${filters.auditStatusAndLastNameMatches}
                }
            ) {
                listing_after_agent_hire_date
                mls_closed_listing_number
                agent_name
                agent_huddle_email
                agent_team
                transaction_buyer
                transaction_seller
                mls_agent_transaction_side
                listing_close_date
                parcel_transfer_date
                listing_address
                parcel_address
                listing_zip
                parcel_zip
                listing_city
                parcel_city
                listing_state
                parcel_state
                listing_year_built
                listing_close_price
                parcel_transfer_value
                parcel_transfer_type
                listing_agent_name
                buyer_agent_name
                listing_broker_office_name
                buyery_agent_office_name
                mls_code
                mls_closed_listing_number
                parcel_transaction_unique_id
                parcel_surrogate_unique_id
                parcel_transfer_data_provider
                parcel_transfer_recency
                date_delta_listing_closed_parcel_transfer
                date_delta_listing_closed_parcel_filing
                agent_source
                agent_state
                agent_huddle3_contact_id
                zips_match
                cities_match
                states_match
                match_quality
                listing_agent_mls_id
                mls_closed_listing_id
                buyer_agent_commission
                listing_agent_commission
                proposed_lead_ids
                auditInfo {
                    auditor_status
                    transaction_buyer_status
                    transaction_seller_status
                    final_disposition
                    manager_status
                    last_modified_datetime
                }
            }
        }
    `;
}

export const NOTIFY_LISTING_TRANSACTION_DATA = gql`
  subscription NotifyListingTransactionData {
    read_only_agent_auditor_listings_transactions_view(limit: 100) {
      mls_closed_listing_number
      agent_name
      agent_team
      agent_huddle3_contact_id
      mls_code
      listing_agent_mls_id
      listing_address
      listing_city
      listing_state
      listing_zip
      listing_close_date
      transaction_buyer
      transaction_seller
    }
  }
`;

export const NOTIFY_NON_TEAM_AGENTS = gql`
  subscription NotifyNonTeamAgents {
    read_only_all_agents(
      where: {
        mls_code: { _eq: "bright" }
        agent_warehouse_generated_team: { _is_null: true }
      }
      order_by: { agent_name: asc }
    ) {
      transactions_aggregate {
        aggregate {
          count(columns: mls_closed_listing_number, distinct: true)
        }
      }
      agent_broker_name
      agent_broker_email
      agent_warehouse_generated_team
      mls_code
      agent_name
      agent_huddle_email
      agent_huddle3_contact_id
    }
  }
`;

export const NOTIFY_AGENT_DETAILS_BY_KEY = gql`
  subscription NotifyAgentDetailsByKey(
    $agentContactId: String = "339c3c93f576cb61d3d89266edb94ad7"
  ) {
    read_only_all_agents(where: { agent_huddle3_contact_id: { _eq: $agentContactId } }) {
      agent_name
      agent_huddle_email
      agent_huddle_id
      mls_code
      mls_id
      agent_warehouse_generated_team
      transactions(order_by: { listing_close_date: desc_nulls_last }) {
        mls_code
        parcel_transfer_data_provider
        parcel_transfer_date
        transaction_buyer
        transaction_seller
        mls_closed_listing_number
        listing_address
        parcel_address
        mls_closed_listing_id
        parcel_surrogate_unique_id
        parcel_transaction_unique_id
      }
      leads {
        archive_reason
        stage
        opportunity_first_last
        created_date
        opportunity_id
        opportunity_owner
      }
      sales {
        opportunity_name
        stage
        under_contract_date
        opportunity_id
        opportunity_first_name
        opportunity_last_name
      }
    }
  }
`;

export const NOTIFY_AUDIT_INFO_BY_ID = gql`
subscription NotifyAuditInfoById(
  $listingId: bigint = ""
  $agentContactId: String = ""
  $parcelTransactionID: String = ""
  $parcelSurrogateID: String = ""
) {
    audit_process_audit_info(
        where: {
          listing_id: {_eq: $listingId}
          agent_huddle3_contact_id: {_eq: $agentContactId}
          parcel_transaction_unique_id: {_eq: $parcelTransactionID}
          parcel_surrogate_unique_id: {_eq: $parcelSurrogateID}
        }
    ) {
      auditor_notes
      auditor_status
      final_disposition
      listing_id
      mail_merge_text
      manager_notes
      manager_status
      parcel_address_matches
      parcel_transfer_date_matches
      parcel_transfer_value_matches
      sale_id
      transaction_buyer_status
      transaction_seller_status
      confirmed_lead_id
      potential_lead_ids
      last_modified_datetime
      agent_huddle3_contact_id
      status_escalation_datetime
      auditor_status_last_modified
      manager_status_last_modified
      final_disposition_last_modified
      auditor_status_last_modified_by
      manager_status_last_modified_by
      final_disposition_last_modified_by
    }
  }
  
`;

export const NOTIFY_LISTING_DETAILS_BY_ID = gql`
  subscription NotifyListingDetailsById($listingId: bigint = "30750678") {
    read_only_agent_auditor_listings_transactions_view(
      where: { mls_closed_listing_id: { _eq: $listingId } }
      order_by: { listing_address: asc, match_quality: asc }
    ) {
      mls_code
      mls_closed_listing_number
      mls_closed_listing_id
      mls_agent_transaction_side
      parcel_address
      parcel_city
      parcel_state
      parcel_transfer_data_provider
      parcel_transfer_date
      parcel_transfer_recency
      parcel_transfer_type
      parcel_transfer_value
      parcel_surrogate_unique_id
      parcel_transaction_unique_id
      parcel_apn
      parcel_raw_apn
      parcel_zip
      transaction_buyer
      transaction_seller
      listing_year_built
      listing_zip
      match_quality
      listing_state
      listing_close_price
      listing_close_date
      listing_city
      listing_broker_office_name
      listing_agent_name
      listing_agent_mls_id
      listing_address
      buyery_agent_office_name
      buyer_agent_name
      audit_record_unique_id
      agent_details {
        agent_hire_date
        agent_broker_name
        agent_broker_email
        agent_brokerage
        agent_duration_of_agreement
        agent_huddle_account
        agent_huddle_email
        agent_huddle_id
        agent_is_active
        agent_huddle3_contact_id
        agent_manager_id
        agent_manager_name
        agent_markets
        agent_name
        agent_office_city
        agent_state
        agent_termination_date
        agent_user_status
        agent_warehouse_generated_team
        leads {
          archive_reason
          stage
          opportunity_first_last
          created_date
          opportunity_id
        }
        sales {
          opportunity_name
          stage
          under_contract_date
          opportunity_id
          opportunity_first_name
          opportunity_last_name
        }
      }
      audit_matches {
        parcel_address_matches
      }
    }
  }
`;
