import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from "@chakra-ui/react";
import {
    UPDATE_MATCHED_ID,
    UPDATE_POTENTIAL_LEADS_ID,
} from "../../graphql/mutations";
import { StatUpArrow, StatDownArrow } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import Fuse from "fuse.js";
import PropTypes from "prop-types";

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
export const HuddleLeadsTable = ({
    leadsData,
    confirmed_lead_id,
    agent_huddle3_contact_id,
    listing_id,
    potential_lead_ids,
    parcel_surrogate_unique_id,
    parcel_transaction_unique_id,
    mls_closing_date,
    transaction_name,
    isLight,
}) => {
    const baseUrl =
        "https://huddle3.lightning.force.com/lightning/r/Opportunity";

    const [selectedValue, setSelectedValue] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("");
    const [sortedLeadsData, setSortedLeadsData] = useState([]);
    const [allLeadsData, setAllLeadsData] = useState([]);
    const [sortDirection, setSortDirection] = useState("asc"); // Initial sort direction
    const [mutateLeadId] = useMutation(UPDATE_MATCHED_ID);
    const [mutatePotentialLeads] = useMutation(UPDATE_POTENTIAL_LEADS_ID);

    function handleRadio(event) {
        setSelectedValue(event.target.value);
        if (
            listing_id &&
            parcel_surrogate_unique_id &&
            parcel_transaction_unique_id &&
            agent_huddle3_contact_id &&
            event.target.value != "clear"
        ) {
            mutateLeadId({
                variables: {
                    agent_huddle3_contact_id: agent_huddle3_contact_id,
                    listing_id: listing_id,
                    sale_id: "",
                    parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                    parcel_transaction_unique_id: parcel_transaction_unique_id,
                    confirmed_lead_id: event.target.value,
                },
            });
        } else if (event.target.value == "clear") {
            mutateLeadId({
                variables: {
                    agent_huddle3_contact_id: agent_huddle3_contact_id,
                    listing_id: listing_id,
                    sale_id: null,
                    parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                    parcel_transaction_unique_id: parcel_transaction_unique_id,
                    confirmed_lead_id: null,
                },
            });
        }
    }

    function sortByFirstName(newSortDirection) {
        const sortedData = [...allLeadsData].sort((a, b) => {
            if (
                a?.person_account_first_name != null &&
                b?.person_account_first_name != null
            ) {
                const firstNameA = a?.person_account_first_name.toLowerCase();
                const firstNameB = b?.person_account_first_name.toLowerCase();

                if (newSortDirection === "asc") {
                    return firstNameA.localeCompare(firstNameB);
                } else {
                    return firstNameB.localeCompare(firstNameA);
                }
            }
        });
        setSelectedFilter("firstName" + newSortDirection);
        setSortedLeadsData(sortedData);
    }

    function sortByLastName(newSortDirection) {
        const sortedData = [...allLeadsData].sort((a, b) => {
            if (
                a?.person_account_last_name != null &&
                b?.person_account_last_name != null
            ) {
                const lastNameA = a?.person_account_last_name.toLowerCase();
                const lastNameB = b?.person_account_last_name.toLowerCase();

                if (newSortDirection === "asc") {
                    return lastNameA.localeCompare(lastNameB);
                } else {
                    return lastNameB.localeCompare(lastNameA);
                }
            }
        });
        setSelectedFilter("lastName" + newSortDirection);
        setSortedLeadsData(sortedData);
    }

    function sortByNameMatch(newSortDirection) {
        const sortedData = [...allLeadsData].sort((a, b) => {
            if (a?.opportunity_id != null && b?.opportunity_id != null) {
                const isNameAMatch =
                    potential_lead_ids == null
                        ? false
                        : potential_lead_ids.some(
                              (id) => id.lead_id === a.opportunity_id
                          );
                const isNameBMatch =
                    potential_lead_ids == null
                        ? false
                        : potential_lead_ids.some(
                              (id) => id.lead_id === b.opportunity_id
                          );

                if (newSortDirection === "asc") {
                    return isNameAMatch - isNameBMatch;
                } else {
                    return isNameBMatch - isNameAMatch;
                }
            }
        });
        setSelectedFilter("nameMatch" + newSortDirection);
        setSortedLeadsData(sortedData);
    }

    function sortByCreatedDate(newSortDirection) {
        const sortedData = [...allLeadsData].sort((a, b) => {
            if (a?.created_date != null && b?.created_date != null) {
                const createdDateA = new Date(a?.created_date);
                const createdDateB = new Date(b?.created_date);

                if (newSortDirection === "asc") {
                    return createdDateA - createdDateB;
                } else {
                    return createdDateB - createdDateA;
                }
            } else if (a?.created_date == null) {
                return 1; // a is sorted to the end
            } else if (b?.created_date == null) {
                return -1; // b is sorted to the end
            }
        });
        setSelectedFilter("createdDate" + newSortDirection);
        setSortedLeadsData(sortedData);
    }

    function handleSort(event) {
        if (event?.target?.id != undefined) {
            const newSortDirection = sortDirection === "asc" ? "desc" : "asc";
            switch (event.target.id) {
                case "firstName":
                    sortByFirstName(newSortDirection);
                    break;
                case "lastName":
                    sortByLastName(newSortDirection);
                    break;
                case "nameMatch":
                    sortByNameMatch(newSortDirection);
                    break;
                case "createdDate":
                    sortByCreatedDate(newSortDirection);
                    break;
                default:
                    break;
            }
            setSortDirection(newSortDirection);
        }
    }

    function handleCheck(event) {
        if (event?.target?.value != null) {
            let jsonB = potential_lead_ids || [];
            if (event.target.checked) {
                jsonB.push({ lead_id: event.target.value });
            } else {
                jsonB = jsonB.filter(
                    (item) => item.lead_id !== event.target.value
                );
            }
            mutatePotentialLeads({
                variables: {
                    agent_huddle3_contact_id: agent_huddle3_contact_id,
                    listing_id: listing_id,
                    parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                    parcel_transaction_unique_id: parcel_transaction_unique_id,
                    potential_lead_ids: jsonB,
                },
            });
        }
    }

    function removePhoneNumberFormatting(phoneNumber) {
        // Use regular expressions to remove unwanted characters
        const cleanedPhoneNumber = phoneNumber?.replace(/[()\s-]/g, "");

        // Check if the cleaned phone number has exactly 10 digits
        if (cleanedPhoneNumber?.length === 10) {
            // Format the phone number as XXX-XXX-XXXX
            return cleanedPhoneNumber.replace(
                /(\d{3})(\d{3})(\d{4})/,
                "$1-$2-$3"
            );
        } else {
            // If the phone number doesn't have 10 digits, return it as is
            return cleanedPhoneNumber;
        }
    }

    useEffect(() => {
        if (leadsData) {
            const filteredLeadsData = leadsData.filter((record) => {
                const createdDate = new Date(record.created_date);
                const listingClosingDate = new Date(mls_closing_date);
                const differenceInDays =
                    (createdDate - listingClosingDate) / (1000 * 60 * 60 * 24);

                return differenceInDays <= 30;
            });


            setAllLeadsData(filteredLeadsData);

            const fuse = new Fuse(filteredLeadsData, {
                includeScore: true,
                keys: ["opportunity_first_last"],
                includeMatches: true,
                // findAllMatches: true,
                shouldSort: true,
            });

            if (transaction_name) {
                const transactionNames = transaction_name.split(",");
                const matchedRecords = transactionNames.flatMap((name) => {
                    const result = fuse.search(name.trim());
                    return result;
                });

                const sortedMatchedRecords = matchedRecords
                    .sort((a, b) => a.score - b.score)
                    .map(({ item }) => item);
                const unmatchedRecords = filteredLeadsData.filter(
                    (record) => !sortedMatchedRecords.includes(record)
                );
                setSortedLeadsData([
                    ...sortedMatchedRecords,
                    ...unmatchedRecords,
                ]);
            } else {
                setSortedLeadsData(leadsData);
            }
        }
    }, [leadsData]);

    return (
        <TableContainer overflowY="auto" overflowX="auto">
            <Table size="sm" variant="striped" colorScheme="whiteAlpha">
                <TableCaption placement="top">Huddle Leads</TableCaption>
                <Thead>
                    <Tr>
                        <Th>Huddle Leads</Th>
                        {!isLight && (
                            <Th
                                id="firstName"
                                onClick={handleSort}
                                style={{ cursor: "pointer" }}
                            >
                                First Name{" "}
                                {selectedFilter.includes("firstName") ? (
                                    selectedFilter.includes("asc") ? (
                                        <StatUpArrow />
                                    ) : (
                                        <StatDownArrow />
                                    )
                                ) : (
                                    " "
                                )}
                            </Th>
                        )}
                        {!isLight && (
                            <Th
                                id="lastName"
                                onClick={handleSort}
                                style={{ cursor: "pointer" }}
                            >
                                Last Name{" "}
                                {selectedFilter.includes("lastName") ? (
                                    selectedFilter.includes("asc") ? (
                                        <StatUpArrow />
                                    ) : (
                                        <StatDownArrow />
                                    )
                                ) : (
                                    " "
                                )}
                            </Th>
                        )}
                        {!isLight && (
                            <Th
                                id="createdDate"
                                onClick={handleSort}
                                style={{ cursor: "pointer" }}
                            >
                                Create Date{" "}
                                {selectedFilter.includes("createdDate") ? (
                                    selectedFilter.includes("asc") ? (
                                        <StatUpArrow />
                                    ) : (
                                        <StatDownArrow />
                                    )
                                ) : (
                                    " "
                                )}
                            </Th>
                        )}
                        {!isLight && <Th id="opportunityOwner">Owner </Th>}
                        <Th
                            id="nameMatch"
                            onClick={handleSort}
                            style={{ cursor: "pointer" }}
                        >
                            Name Match{" "}
                            {selectedFilter.includes("nameMatch") ? (
                                selectedFilter.includes("asc") ? (
                                    <StatUpArrow />
                                ) : (
                                    <StatDownArrow />
                                )
                            ) : (
                                " "
                            )}
                        </Th>
                        <Th>
                            Missed Sale{" "}
                            <input
                                type="radio"
                                name="clear"
                                checked={confirmed_lead_id == null}
                                value={"clear"}
                                onChange={handleRadio}
                            />
                        </Th>
                    </Tr>
                </Thead>
                <Tbody style={{ overflow: "auto" }}>
                    {sortedLeadsData.map((lead, index) => {
                        const opportunityUrl = `${baseUrl}/${lead?.opportunity_id}/view`;
                        return (
                            <Tr key={index}>
                                <Td>
                                    <a
                                        style={{ color: "blue" }}
                                        href={opportunityUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {lead?.opportunity_first_last} -{" "}
                                        {lead?.created_date}
                                    </a>
                                </Td>
                                {!isLight && (
                                    <Td>{lead?.person_account_first_name}</Td>
                                )}
                                {!isLight && (
                                    <Td>{lead?.person_account_last_name}</Td>
                                )}
                                {!isLight && <Td>{lead?.created_date}</Td>}
                                {!isLight && <Td>{lead?.opportunity_owner}</Td>}

                                <Td style={{ textAlign: "center" }}>
                                    <input
                                        key={lead?.opportunity_id}
                                        type="checkbox"
                                        checked={
                                            potential_lead_ids == null
                                                ? false
                                                : potential_lead_ids.some(
                                                      (id) =>
                                                          id.lead_id ===
                                                          lead.opportunity_id
                                                  )
                                        }
                                        value={lead?.opportunity_id}
                                        onChange={handleCheck}
                                    ></input>
                                </Td>
                                <Td>
                                    <input
                                        key={lead?.opportunity_id}
                                        type="radio"
                                        name="match"
                                        checked={
                                            lead?.opportunity_id ==
                                            confirmed_lead_id
                                        }
                                        value={lead?.opportunity_id}
                                        onChange={handleRadio}
                                    />
                                </Td>
                                {!isLight &&<Td>
                                    <a
                                        href={
                                            "https://www.fastpeoplesearch.com/" +
                                            removePhoneNumberFormatting(
                                                lead?.phone
                                            )
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <FaSearch></FaSearch>
                                    </a>
                                </Td>}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

HuddleLeadsTable.propTypes = {
    leadsData: PropTypes.arrayOf(
        PropTypes.shape({
            opportunity_id: PropTypes.string,
            opportunity_name: PropTypes.string,
            person_account_first_name: PropTypes.string,
            person_account_last_name: PropTypes.string,
            created_date: PropTypes.string,
            opportunity_owner: PropTypes.string,
        })
    ),
    confirmed_lead_id: PropTypes.string,
    agent_huddle3_contact_id: PropTypes.string,
    listing_id: PropTypes.number,
    potential_lead_ids: PropTypes.arrayOf(
        PropTypes.shape({
            lead_id: PropTypes.string,
        })
    ),
    parcel_surrogate_unique_id: PropTypes.string,
    parcel_transaction_unique_id: PropTypes.string,
    mls_closing_date: PropTypes.string,
    transaction_name: PropTypes.string,
    isLight: PropTypes.bool,
};

export default HuddleLeadsTable;
