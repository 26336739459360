import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import './IndividualRecordsTableColumns.css';
//Emojis to use 🟢 🔴

const columnHelper = createColumnHelper();
export const columns = [
    columnHelper.accessor(row => row.mls_code, {
        header: 'Agent MLS',
        id: 'agentMls',
        recordDataId: 'mls_code',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.agent_name, {
        header: 'Agent Name',
        id: 'agentName',
        recordDataId: 'agent_name',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.agent_team, {
        header: 'Agent Team',
        id: 'agentTeam',
        recordDataId: 'agent_team',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.agent_team, {
        header: 'Name Match',
        id: 'nameMatch',
        recordDataId: 'proposed_lead_ids',
        cell: (info) => (
            <div style={{textAlign: 'center'}}><input readOnly type='checkbox' checked={info.row.original?.proposed_lead_ids !== null} /></div>
        )
    }),
    columnHelper.accessor(row => (
        `${row.listing_address}, ${row.listing_city}, ${row.listing_state} ${row.listing_zip}`
    ), {
        header: 'Listing Address',
        id: 'listingAddress',
        recordDataId: 'listing_address',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.listing_close_date, {
        header: 'Listing Close Date',
        id: 'listingCloseDate',
        recordDataId: 'listing_close_date',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.transaction_seller, {
        header: "Transaction Seller",
        id: 'transactionSeller',
        recordDataId: 'transaction_seller',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.transaction_buyer, {
        header: "Transaction Buyer",
        id: 'transactionBuyer',
        recordDataId: 'transaction_buyer',
        cell: info => info.getValue()
    }),
    columnHelper.accessor(row => row.transaction_buyer, {
        header: "Auditor Status",
        id: 'auditorStatus',
        recordDataId: 'transaction_buyer',
        style: { textAlign: 'center'},
        cell: (info) => (
            <div style={{textAlign: 'center'}}>{info?.row?.original?.auditInfo?.auditor_status ? '🟢' : '🔴'}</div>
        )
    }),
    columnHelper.accessor(row => row.transaction_buyer, {
        header: "Manager Status",
        id: 'managerStatus',
        recordDataId: 'transaction_buyer',
        meta: {
            style: { textAlign: 'center'},
        },
        cell: (info) => (
            <div style={{textAlign: 'center'}}>{info?.row?.original?.auditInfo?.manager_status ? '🟢' : '🔴'}</div>
        )
    }),
    columnHelper.accessor(row => row.transaction_buyer, {
        header: "Final Disposition",
        id: 'finalDisposition',
        recordDataId: 'transaction_buyer',
        style: { textAlign: 'center'},
        cell: (info) => (
            <div style={{textAlign: 'center'}}>{info?.row?.original?.auditInfo?.final_disposition ? '🟢' : '🔴'}</div>
        )
    }),
    columnHelper.accessor(row => row.mls_closed_listing_id, {
        header: "View Record",
        id: 'agentRecords',
        cell: (info) => (
            <button
                onClick={() => {
                    const url = `/listing/${info.row.original.mls_closed_listing_id}/agent/${info.row.original.agent_huddle3_contact_id}/transaction/${info.row.original.parcel_transaction_unique_id}/surrogate/${info.row.original.parcel_surrogate_unique_id}`;
                    window.open(url, '_blank');
                }}
            >
                View Record
            </button>
        )
    })
];
