import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_MLS_DATA } from "../graphql/queries";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { selectStyles } from "../styles";
import PropTypes from "prop-types";
import { formatMlsData, clearFilter } from "../utils";
import { appContext } from "../AppProvider";

const MlsFilter = ({ onFilterChange, value }) => {
  const { setMlsFilter, setGroupedRecordOffset, setRecordOffset } =
    useContext(appContext);

    const navigate = useNavigate();


  const { loading, error, data } = useQuery(GET_MLS_DATA);

  const mlsData = data?.read_only_mlses;

  const [mlsList, setMlsList] = useState(clearFilter);

  if (error) console.error(error);

  useEffect(() => {
    const mlses = formatMlsData(mlsData);
    setMlsList(clearFilter.concat(mlses));
  }, [mlsData]);

  const handleMlsCodeSelection = (target) => {
    setGroupedRecordOffset(0);
    setRecordOffset(0);
    if (target.value === null) {
      setMlsFilter("%");
    } else {
        if (target.value) {
            onFilterChange({ mlsFilter: target.value });
        } else {
            const currentParams = new URLSearchParams(location.search);
            currentParams.delete('mlsFilter'); // Delete the mlsFilter parameter
            navigate(location.pathname + '?' + currentParams.toString()); // Navigate to the new URL      
        }
        setMlsFilter(target.value);
    }
  };

  if (loading) {
    return (
      <Select
        options={clearFilter}
        placeholder="Loading MLS List..."
        styles={selectStyles}
      />
    );
  }

  const selectedOption = mlsList.find(option => option.value === (value));

  return (
    <Select
      options={mlsList}
      placeholder="Select MLS"
      onChange={handleMlsCodeSelection}
      value={selectedOption} 
      styles={selectStyles}
    />
  );
};
MlsFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default MlsFilter;
