export const selectStyles = {
    option: (styles, { isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "rgb(32, 160, 47, 0.2)" : null,
            borderBlockColor: isFocused ? "rgb(32, 160, 47)" : null,
            color: isSelected ? "rgb(32, 160, 47)" : "default",
            overflow: 'visible',
        };
    }
}

export const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // backgroundColor: "rgb(32, 160, 47)",
    fontSize: '1.25em',
    fontWeight: 'bold',
    color: "rgb(32, 160, 47)",
};

export const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};
