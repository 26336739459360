import React, { useEffect, useContext, useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MlsFilter from "../components/MlsFilter";
import AgentFilter from "../components/AgentFilter";
import AuditorStatusFilter from "../components/AuditorStatusFilter";
import ManagerStatusFilter from "../components/ManagerStatusFilter";
import PropTypes from "prop-types";
import FinalDispositionFilter from "../components/FinalDispositionFilter";
import { appContext } from "../AppProvider";

const RecordFilterContainer = ({ groupByAgent, toggleGrouping }) => {
    const navigate = useNavigate();
    const location = useLocation();
    
    const { setMlsFilter, setAgentFilter, setTeamFilter, setStatus, status} =
    useContext(appContext);

    const [mlsSelected, setMlsSelected] = useState(null);
    const [agentSelected, setAgentSelected] = useState(null);
    const [teamSelected, setTeamSelected] = useState(null);
    const [auditorStatusSelected, setAuditorStatusSelected] = useState(null);
    const [managerStatusSelected, setManagerStatusSelected] = useState(null);
    const [finalDispositionSelected, setFinalDispositionSelected] = useState(null);
  
    const updateUrl = (newFilters) => {
      const currentParams = new URLSearchParams(location.search);
      Object.keys(newFilters).forEach((key) => {
        currentParams.set(key, newFilters[key]);
      });
      navigate(location.pathname + '?' + currentParams.toString());
    };

  const onFilterChange = (newFilters) => {
    updateUrl(newFilters);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mlsFilter = queryParams.get('mlsFilter');
    const agentFilter = queryParams.get('agentFilter') !== 'false' ? queryParams.get('agentFilter') : false;
    const teamFilter = queryParams.get('teamFilter') !== 'false' ? queryParams.get('teamFilter') : false;
    
    setMlsFilter(mlsFilter || '%');
    setMlsSelected(mlsFilter || null);

    setAgentSelected(agentFilter || null);
    setAgentFilter(agentFilter || false);

    setTeamSelected(teamFilter || null);
    setTeamFilter(teamFilter || false);
    
    
    if (queryParams.get('auditorStatus') != null) {
      let auditorStatus = queryParams.get('auditorStatus');
      let auditorStatusArray = auditorStatus.includes(',') ? auditorStatus.split(',') : [auditorStatus];
      setAuditorStatusSelected(auditorStatusArray);
      status.auditor = auditorStatusArray || false;
      setStatus(status);
  }

    if (queryParams.get('managerStatus') != null) {
      let managerStatus = queryParams.get('managerStatus');
      let managerStatusArray = managerStatus.includes(',') ? managerStatus.split(',') : [managerStatus];
      setManagerStatusSelected(managerStatusArray);
      status.manager = managerStatusArray || false;
      setStatus(status);
  }

  if (queryParams.get('finalDispositionStatus') != null) {
    let finalDispositionStatus = queryParams.get('finalDispositionStatus');
    let finalDispositionStatusArray = finalDispositionStatus.includes(',') ? finalDispositionStatus.split(',') : [finalDispositionStatus];
    setFinalDispositionSelected(finalDispositionStatusArray);
    status.finalDisposition = finalDispositionStatusArray || false;
    setStatus(status);
}

    if (queryParams.get('toggleGrouping') != null && queryParams.get('toggleGrouping') != 'true') {
        toggleGrouping(queryParams.get('toggleGrouping'));
    }

  }, []);

  return (
    <div id="record-filters-container">
      <div className="filter-first-row">
        <span className="filter-row-element">
          <MlsFilter value={mlsSelected} onFilterChange={onFilterChange} />
        </span>
        <span className="filter-row-element">
          <AgentFilter agentValue={agentSelected} teamValue={teamSelected} onFilterChange={onFilterChange} />
        </span>
        <span className="filter-row-element" id="group-view-toggle">
          <input
            type="checkbox"
            id="group-by-agent"
            checked={groupByAgent}
            onChange={(event) => {
                toggleGrouping(event);
                onFilterChange({ toggleGrouping: event.target.checked });
            }}
          />
          <label htmlFor="grouped">Group By Agent</label>
        </span>
      </div>
      <div className="filter-second-row">
        <span className="filter-row-element">
          <AuditorStatusFilter value={auditorStatusSelected} onFilterChange={onFilterChange} />
        </span>
        <span className="filter-row-element">
          <ManagerStatusFilter value={managerStatusSelected} onFilterChange={onFilterChange}/>
        </span>
        <span className="filter-row-element">
          <FinalDispositionFilter value={finalDispositionSelected} onFilterChange={onFilterChange}/>
        </span>
      </div>
    </div>
  );
};

RecordFilterContainer.propTypes = {
  groupByAgent: PropTypes.bool.isRequired,
  toggleGrouping: PropTypes.func.isRequired,
};

export default RecordFilterContainer;
