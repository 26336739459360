import React from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';

const SecureRoute = ({children}) => {
  const { oktaAuth, authState } = useOktaAuth();

  if (!authState) {
    return <div>Loading...</div>;
  }

  if (!authState.isAuthenticated) {
    const originalUri = window.location.pathname + window.location.search + window.location.hash;
    oktaAuth.setOriginalUri(originalUri);
    oktaAuth.signInWithRedirect();
    return null;
  }

  return children;
};

SecureRoute.propTypes = {
    children: PropTypes.node,
};

export default SecureRoute;
