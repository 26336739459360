// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#auditorStatus{
    text-align: center;
}
#managerStatus{
    text-align: center;
}
#finalDisposition{
    text-align: center;
}

th {
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/subcomponents/IndividualRecordsTableColumns.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["#auditorStatus{\n    text-align: center;\n}\n#managerStatus{\n    text-align: center;\n}\n#finalDisposition{\n    text-align: center;\n}\n\nth {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
