import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  UPDATE_TRANSACTION_BUYER_STATUS,
  UPDATE_TRANSACTION_SELLER_STATUS,
} from "../../../graphql/mutations";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import Select from "react-select";
import { nullFilter } from "../../../utils";

const transactionOptions = [
  { value: "Not a Match", label: "Not a Match" },
  { value: "Not Relevant", label: "Not Relevant" },
  { value: "Fuzzy Match", label: "Fuzzy Match" },
  { value: "Exact Match", label: "Exact Match" },
  {
    value: "Our Agent Didn’t Help This Customer",
    label: "Our Agent Didn’t Help This Customer",
  },
  { value: "Before Hire", label: "Before Hire" },
  { value: "After 2-year expiration", label: "After 2-year expiration" },
  { value: "Paid in Huddle", label: "Paid in Huddle" },
];

/* eslint-disable react/prop-types */
export const TransactionStatus = ({
  text,
  agent_huddle3_contact_id,
  listing_id,
  parcel_surrogate_unique_id,
  parcel_transaction_unique_id,
  placeholder,
  type,
  name,
  information,
  audit_record_unique_id,
}) => {
  const [inputValue, setInputValue] = useState(text);
  const [transactionOptionList, setTransactionOptionList] =
    useState(transactionOptions);
  const [mutateTransactionBuyerStatus] = useMutation(
    UPDATE_TRANSACTION_BUYER_STATUS
  );
  const [mutateTransactionSellerStatus] = useMutation(
    UPDATE_TRANSACTION_SELLER_STATUS
  );


  useEffect(() => {
    setTransactionOptionList(nullFilter.concat(transactionOptions));
    switch (type) {
      case "transaction_buyer_status":
        if (
          listing_id &&
          parcel_surrogate_unique_id &&
          parcel_transaction_unique_id &&
          text != inputValue
        ) {
          mutateTransactionBuyerStatus({
            variables: {
              agent_huddle3_contact_id: agent_huddle3_contact_id,
              listing_id: listing_id,
              transaction_buyer_status: inputValue,
              parcel_surrogate_unique_id: parcel_surrogate_unique_id,
              parcel_transaction_unique_id: parcel_transaction_unique_id,
              audit_record_unique_id: audit_record_unique_id,
            },
          });
        }
        break;
      case "transaction_seller_status":
        if (
          listing_id &&
          parcel_surrogate_unique_id &&
          parcel_transaction_unique_id &&
          text != inputValue
        ) {
          mutateTransactionSellerStatus({
            variables: {
              agent_huddle3_contact_id: agent_huddle3_contact_id,
              listing_id: listing_id,
              transaction_seller_status: inputValue,
              parcel_surrogate_unique_id: parcel_surrogate_unique_id,
              parcel_transaction_unique_id: parcel_transaction_unique_id,
              audit_record_unique_id: audit_record_unique_id,
            },
          });
        }
        break;
      default:
        break;
    }
    setInputValue(inputValue);
  }, [inputValue]);

  const handleUpdateStatus = (value) => {
    setInputValue(value);
  };

  if (transactionOptionList != null) {
    return (
      <Card>
        <CardHeader fontWeight="bold">
          {name != "" ? name : "No Data"}
        </CardHeader>
        <CardBody>
          <span style={{ marginBottom: "10px" }}>
            {information != null ? information : "No Data"}
          </span>
          <Select
            menuPosition="fixed"
            styles={{ position: "absolute" }}
            options={transactionOptionList}
            onChange={(e) => handleUpdateStatus(e.value)}
            value={transactionOptionList.filter(
              (option) => option.label?.toLowerCase() === text?.toLowerCase()
            )}
            placeholder={placeholder}
          ></Select>
        </CardBody>
      </Card>
    );
  }
};
export default TransactionStatus;
