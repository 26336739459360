import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Stack } from "@chakra-ui/react";
import Select from "react-select";
import {
  auditorStatusOptions,
  managerStatusOptions,
  finalDispositionStatusOptions,
  nullFilter
} from "../../../utils";
import {
  UPDATE_AUDITOR_STATUS,
  UPDATE_MANAGER_STATUS,
  UPDATE_FINAL_DISPOSITION_STATUS,
} from "../../../graphql/mutations";
import { useUserProfile } from "../../../oktaUtils";

/* eslint-disable react/prop-types */
export const FinalResultStatus = ({
  text,
  listing_id,
  placeholder,
  type,
  name,
  parcel_surrogate_unique_id,
  parcel_transaction_unique_id,
  agent_huddle3_contact_id,
  audit_record_unique_id,
}) => {
  const [inputValue, setInputValue] = useState(text);
  const [mutateAuditorStatus] = useMutation(UPDATE_AUDITOR_STATUS);
  const [mutateManagerStatus] = useMutation(UPDATE_MANAGER_STATUS);
  const [mutateFinalDisposition] = useMutation(UPDATE_FINAL_DISPOSITION_STATUS);
  const [statusesList, setStatusesList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const { userInfo, isLoading, error } = useUserProfile();
  const [ userName, setUserName ] = useState(null);


  const emptyValues = ['', null, undefined, 'noStatus'];

  useEffect(() => {
    if (!isLoading && !error) {
      if (userInfo?.name) {
        setUserName(userInfo.name);
      }
    } else {
      setUserName(null);
    }
  }, [userInfo, isLoading, error]);
        



  const handleStatusChange = (type, inputValue) => {
    switch (type) {
        case "auditor_status":
            setStatusesList(nullFilter.concat(auditorStatusOptions));
            if (!initialLoad && listing_id && parcel_surrogate_unique_id && parcel_transaction_unique_id) {
              if (inputValue == "Clear") {
                mutateAuditorStatus({
                    variables: {
                        listing_id: listing_id,
                        agent_huddle3_contact_id: agent_huddle3_contact_id,
                        auditor_status: inputValue,
                        manager_status: "Clear",
                        final_disposition: "Clear",
                        auditor_status_last_modified_by: userName,
                        manager_status_last_modified_by: userName,
                        final_disposition_last_modified_by: userName,
                        auditor_status_last_modified: new Date(),
                        manager_status_last_modified: new Date(),
                        final_disposition_last_modified: new Date(),
                        parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                        parcel_transaction_unique_id: parcel_transaction_unique_id,
                        audit_record_unique_id: audit_record_unique_id
                    },
                });
              } else {
                if (!initialLoad) {
                  mutateAuditorStatus({
                    variables: {
                        listing_id: listing_id,
                        agent_huddle3_contact_id: agent_huddle3_contact_id,
                        auditor_status: inputValue,
                        manager_status: "",
                        final_disposition: "",
                        auditor_status_last_modified: new Date(),
                        manager_status_last_modified: null,
                        final_disposition_last_modified: null,
                        auditor_status_last_modified_by: userName,
                        parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                        parcel_transaction_unique_id: parcel_transaction_unique_id,
                        audit_record_unique_id: audit_record_unique_id
                    },
                  });
                }
              }
            }
            break;
        case "manager_status":
            setStatusesList(nullFilter.concat(managerStatusOptions));
            if (!initialLoad && listing_id && parcel_surrogate_unique_id && parcel_transaction_unique_id) {
                mutateManagerStatus({
                    variables: {
                        listing_id: listing_id,
                        agent_huddle3_contact_id: agent_huddle3_contact_id,
                        manager_status: inputValue,
                        manager_status_last_modified: inputValue != null ? new Date() : null,
                        manager_status_last_modified_by: userName,
                        status_escalation_datetime: emptyValues.includes(text) && (inputValue === 'Escalate' || inputValue === 'Definitive') ? new Date() : null,
                        parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                        parcel_transaction_unique_id: parcel_transaction_unique_id,
                        audit_record_unique_id: audit_record_unique_id
                    },
                });
            }
            break;
        case "final_disposition":
            setStatusesList(nullFilter.concat(finalDispositionStatusOptions));
            if (!initialLoad && listing_id && parcel_surrogate_unique_id && parcel_transaction_unique_id) {
                mutateFinalDisposition({
                    variables: {
                        listing_id: listing_id,
                        agent_huddle3_contact_id: agent_huddle3_contact_id,
                        final_disposition: inputValue,
                        final_disposition_last_modified: inputValue != null ? new Date() : null,
                        final_disposition_last_modified_by: userName,
                        parcel_surrogate_unique_id: parcel_surrogate_unique_id,
                        parcel_transaction_unique_id: parcel_transaction_unique_id,
                        audit_record_unique_id: audit_record_unique_id
                    },
                });
            }
            break;
        default:
            break;
    }
}



  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      setInputValue(inputValue);
    }
    handleStatusChange(type, inputValue);
    setInputValue(inputValue);
  }, [inputValue]);

  const handleUpdateStatus = (value) => {
    setInputValue(value);
  };

  if (statusesList != null && listing_id && parcel_surrogate_unique_id && parcel_transaction_unique_id) {
    return (
      <Stack style={{ width: "90%" }}>
        <label>{name}</label>
        <Select
          menuPosition="fixed"
          styles={{ position: "absolute", width: "90%" }}
          options={statusesList}
          onChange={(e) => handleUpdateStatus(e.value)}
          value={statusesList.filter((option) => option.label?.toLowerCase() === text?.toLowerCase())}
          placeholder={placeholder}
        ></Select>
      </Stack>
    );
  }
};
export default FinalResultStatus;
