import { gql } from "@apollo/client";

export const GET_AGENT_DATA = gql`
    query getAgentData {
        read_only_all_agents(order_by: { agent_name: asc }) {
            agent_name
            agent_huddle3_contact_id
            agent_warehouse_generated_team
        }
    }
`;

export const GET_MLS_DATA = gql`
    query getMlsData {
        read_only_mlses(where: { active: { _eq: true } }) {
            code
        }
    }
`;

/// Make sure to keep GET_GROUPED_AGENT_DATA_COUNT filters aligned with this query
export function GET_GROUPED_AGENT_DATA(column, direction, filters) {
    var orderByColumn = "mls_code";
    if (
        column === "listing_address" ||
        column === "agent_team" ||
        column === "listing_close_date" ||
        column === "transaction_seller" ||
        column === "transaction_buyer" ||
        column === "proposed_lead_ids" ||
        column === "auditor_status" ||
        column === "manager_status" ||
        column === "final_disposition"
    ) {
        orderByColumn = "mls_code";
    } else {
        orderByColumn = column;
    }


    return gql`
        query getGroupedAgentData(
            $offset: Int
            $limit: Int
        ) {
            read_only_all_agents(
                limit: $limit
                offset: $offset
                order_by: [{${orderByColumn}: ${direction}}, {agent_huddle3_contact_id: asc}]
                where: {
                    ${filters.mlsQueryPart}
                    ${filters.agentQueryPart}
                    ${filters.groupedTeamQueryPart}
                }
            ) {
                transactions_aggregate(where: {
                    ${filters.noPublicInfoFilterPart}
                    ${filters.individualAuditInfoQueryPart}
                    ${filters.auditStatusAndLastNameMatches}
                    }) {
                        aggregate {
                            count(columns: [mls_closed_listing_number,parcel_transaction_unique_id, parcel_surrogate_unique_id], distinct: true)
                        }
                    }
                agent_huddle3_contact_id
                agent_broker_name
                agent_broker_email
                agent_warehouse_generated_team
                mls_code
                agent_name
                agent_huddle_email
            }
        }
    `;
}

//// This needs to match the same filters that GET_GROUPED_AGENT_DATA has.
export function GET_GROUPED_AGENT_DATA_COUNT(filters) {
    return gql`
        query getGroupedAgentDataCount {
          read_only_agent_auditor_listings_transactions_view_aggregate(
          order_by: {agent_name: asc}, 
          where: {
            ${filters.mlsQueryPart}
            ${filters.agentQueryPart}
            ${filters.groupedTeamQueryPart}
            ${filters.individualAuditInfoQueryPart}
            ${filters.auditStatusAndLastNameMatches}
          }
          ) {
            aggregate {
              count(columns: agent_name, distinct: true)
            }
          }
        }
    `;
}

/// Make sure to keep GET_INDIVIDUAL_LISTING_DATA_COUNT filters aligned with this query
export function GET_INDIVIDUAL_LISTINGS_DATA_EXPORT(
    column,
    direction,
    filters
) {
    let defaultAddressSort = '';

    if (column === "" || column === "mls_code"
    ) { // default sort
        defaultAddressSort = "{listing_address: asc},";
    }
    return gql`
        query getIndividualListingsDataExport @cached(refresh: true){
            read_only_agent_auditor_listings_transactions_view(
                order_by: [${defaultAddressSort}{${column}: ${direction}}]
                where: {
                ${filters.mlsQueryPart}
                ${filters.agentQueryPart}
                ${filters.individualTeamQueryPart}
                ${filters.individualAuditInfoQueryPart}
                ${filters.noPublicInfoFilterPart}
                ${filters.auditStatusAndLastNameMatches}
                }
            ) {
                listing_after_agent_hire_date
                mls_closed_listing_number
                agent_name
                agent_huddle_email
                agent_team
                transaction_buyer
                transaction_seller
                mls_agent_transaction_side
                listing_close_date
                parcel_transfer_date
                listing_address
                parcel_address
                listing_zip
                parcel_zip
                listing_city
                parcel_city
                listing_state
                parcel_state
                listing_year_built
                listing_close_price
                parcel_transfer_value
                parcel_transfer_type
                listing_agent_name
                buyer_agent_name
                listing_broker_office_name
                buyery_agent_office_name
                mls_code
                mls_closed_listing_number
                parcel_transaction_unique_id
                parcel_surrogate_unique_id
                parcel_transfer_data_provider
                parcel_transfer_recency
                date_delta_listing_closed_parcel_transfer
                date_delta_listing_closed_parcel_filing
                agent_source
                agent_state
                agent_huddle3_contact_id
                zips_match
                cities_match
                states_match
                match_quality
                listing_agent_mls_id
                mls_closed_listing_id
                buyer_agent_commission
                listing_agent_commission
                auditInfo {
                    transaction_buyer_status
                    transaction_seller_status
                    auditor_status
                    final_disposition
                    manager_status
                    auditor_notes
                    manager_notes
                    mail_merge_text
                    confirmed_lead {
                        phone
                        opportunity_id
                        program
                        person_account_email
                        created_date
                        opportunity_first_last
                        person_account_first_name
                        person_account_last_name
                        opportunity_owner
                    
                    }
                }
            }
        }
    `;
}

//// This needs to match the same filters that GET_INDIVIDUAL_LISTINGS_DATA has.
export function GET_INDIVIDUAL_LISTINGS_DATA_COUNT(filters) {
    return gql`
        query getIndividualListingsDataCount {
            read_only_agent_auditor_listings_transactions_view_aggregate(
                distinct_on: [parcel_transaction_unique_id,mls_closed_listing_id, parcel_surrogate_unique_id, agent_huddle3_contact_id]
                order_by: [{parcel_transaction_unique_id: asc}, {parcel_surrogate_unique_id: asc}, {agent_huddle3_contact_id: asc}, {mls_closed_listing_id: asc}]
                where: {
                ${filters.mlsQueryPart}
                ${filters.agentQueryPart}
                ${filters.individualTeamQueryPart}
                ${filters.individualAuditInfoQueryPart}
                ${filters.noPublicInfoFilterPart}
                ${filters.auditStatusAndLastNameMatches}
                }
            ) {
                aggregate {
                    count(columns: [mls_closed_listing_id, parcel_transaction_unique_id, parcel_surrogate_unique_id, agent_huddle3_contact_id], distinct: true)
                }
            }
        }
    `;
}

export const GET_AGENT_AUDIT_DATA = gql`
    query getAgentAuditData($agent_huddle3_contact_id: String = "") {
        read_only_all_agents(
            where: {
                agent_huddle3_contact_id: { _eq: $agent_huddle3_contact_id }
            }
        ) {
            agent_name
            agent_office_city
            agent_office_state
            agent_office_street
            agent_office_zip_code
            agent_huddle_email
            agent_manager_name
            agent_brokerage
            agent_broker_name
            agent_broker_email
            agent_duration_of_agreement
            agent_hire_date
            agent_termination_date
            agent_huddle3_contact_id
            agent_warehouse_generated_team
            mls_code
            agent_broker_phone
            agent_phone
        }
    }
`;

export function GET_LEADS_AND_SALES(filter) {
    return gql`
        query GetLeadsAndSales {
            read_only_all_agents(
                ${filter}
            ) {
                agent_name
                agent_huddle3_contact_id
                agent_warehouse_generated_team
                leads (where: { _not: { person_account_first_name: { _eq: "" } } }) {
                    phone
                    opportunity_id
                    created_date
                    opportunity_first_last
                    person_account_first_name
                    person_account_last_name
                    opportunity_owner
                }
                sales {
                    opportunity_name
                    property_address
                    opportunity_id
                    estimated_closing_date
                    home_sale_close_date
                    created_date
                    opportunity_first_name
                    opportunity_last_name
                }
            }
        }
    `;
}

export function GET_LEADS_AND_SALES_LIGHTER(filter) {
    return gql`
        query GetLeadsAndSales {
            read_only_all_agents(
                ${filter}
            ) {
                agent_name
                leads (where: { _not: { person_account_first_name: { _eq: "" } } }) {
                    opportunity_id
                    opportunity_first_last
                    created_date
                }
                sales {
                    opportunity_name
                    opportunity_id
                    property_address
                }
            }
        }
    `;
}

export function GET_LEADS_AND_SALES_COUNT(filter) {
    return gql`
  query GetLeadsAndSales{
    read_only_all_agents(
        ${filter}
    ) {
    agent_name
    agent_warehouse_generated_team
    __typename
    sales_aggregate {
      aggregate {
        count(distinct: true, columns: opportunity_id)
      }
    }
    leads_aggregate(where: {person_account_first_name: {_neq: ""}}) {
      aggregate {
        count(distinct: true, columns: opportunity_id)
      }
    }
  }
}
`;
}

export const GET_AGENT_AUDIT_RECORD = gql`
    query GetAgentAuditRecord(
        $listing_Id: bigint = ""
        $agentContactId: String = ""
        $parcelTransactionID: String = ""
        $parcelSurrogateID: String = ""
    ) {
        read_only_agent_auditor_listings_transactions_view(
            where: {
                mls_closed_listing_id: { _eq: $listing_Id }
                agent_huddle3_contact_id: { _eq: $agentContactId }
                parcel_surrogate_unique_id: { _eq: $parcelSurrogateID }
                parcel_transaction_unique_id: { _eq: $parcelTransactionID }
            }
        ) {
            mls_closed_listing_id
            agent_huddle3_contact_id
            agent_name
            agent_team
            buyer_agent_name
            transaction_buyer
            transaction_seller
            listing_address
            listing_city
            listing_close_date
            listing_close_price
            listing_state
            parcel_address
            parcel_transfer_value
            parcel_transfer_date
            parcel_city
            parcel_state
            parcel_surrogate_unique_id
            parcel_transaction_unique_id
            mls_agent_transaction_side
            audit_record_unique_id
            agent_details {
                agent_broker_phone
                agent_phone
            }
        }
    }
`;

export const GET_NO_STATUS_REPORT = gql`
    query getAgentAuditorListings {
        read_only_agent_auditor_listings_transactions_view(
            distinct_on: [
                parcel_transaction_unique_id
                parcel_surrogate_unique_id
                agent_huddle3_contact_id
                mls_closed_listing_id
                mls_code
            ]
            order_by: [
                { mls_code: asc }
                { parcel_transaction_unique_id: asc }
                { parcel_surrogate_unique_id: asc }
                { agent_huddle3_contact_id: asc }
                { mls_closed_listing_id: asc }
            ]
            where: {
                _and: [
                    {
                        _not: {
                            parcel_surrogate_unique_id: { _eq: "" }
                            parcel_transaction_unique_id: { _eq: "" }
                        }
                    }
                    { proposed_lead_ids: { _neq: "" } }
                    {
                        _or: [
                            { _not: { auditInfo: {} } }
                            {
                                auditInfo: {
                                    auditor_status: { _is_null: true }
                                }
                            }
                            { auditInfo: { auditor_status: { _eq: "" } } }
                        ]
                    }
                    { mls_code: { _ilike: "%" } }
                ]
            }
        ) {
            agent_details {
                mls_assignment_id
                mls_id
                mls_name
            }
            agent_huddle3_contact_id
            mls_code
            agent_name
            agent_huddle_email
            agent_state
            agent_team
            parcel_transaction_unique_id
            parcel_surrogate_unique_id
            agent_huddle3_contact_id
            mls_closed_listing_id
            mls_code
            __typename
        }
    }
`;

export const GET_TOTAL_RECORDS_REPORT = gql`
    query getGroupedAgentData {
        read_only_all_agents(
            order_by: [{ mls_code: asc }, { agent_huddle3_contact_id: asc }]
            where: { mls_code: { _ilike: "%" } }
        ) {
            transactions_aggregate(
                where: {
                    _and: [
                        {
                            _not: {
                                parcel_surrogate_unique_id: { _eq: "" }
                                parcel_transaction_unique_id: { _eq: "" }
                            }
                        }
                    ]
                    _or: [
                        { proposed_lead_ids: { _neq: "" } }
                        { auditInfo: { auditor_status: { _neq: "" } } }
                    ]
                }
            ) {
                aggregate {
                    count(
                        columns: [
                            mls_closed_listing_number
                            parcel_transaction_unique_id
                            parcel_surrogate_unique_id
                        ]
                        distinct: true
                    )
                    __typename
                }
                __typename
            }
            agent_huddle3_contact_id
            agent_broker_name
            agent_broker_email
            agent_warehouse_generated_team
            mls_code
            agent_name
            agent_huddle_email
            __typename
        }
    }
`;
