import React from "react";

import {
  Table,
  TableContainer,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";

/* eslint-disable react/prop-types */
export const AgentInfoSection = ({ agentInfo, auditRecord }) => {
  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th> </Th>
            <Th> </Th>
            <Th></Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Name</span>
            </Td>
            <Td>{agentInfo?.agent_name}</Td>
            <Td>
              <span style={{ fontWeight: "bold" }}>Duration of Agreement</span>
            </Td>
            <Td>{agentInfo?.agent_duration_of_agreement}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Agent Team</span>
            </Td>
            <Td>{auditRecord?.agent_team}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Email</span>
            </Td>
            <Td>{agentInfo?.agent_huddle_email}</Td>
            <Td>
              <span style={{ fontWeight: "bold" }}>MLS</span>
            </Td>
            <Td>{agentInfo?.mls_code}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Manager</span>
            </Td>
            <Td>{agentInfo?.agent_manager_name}</Td>
            <Td>
              <span style={{ fontWeight: "bold" }}>City</span>
            </Td>
            <Td>{agentInfo?.agent_office_city}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Hire Date</span>{" "}
            </Td>
            <Td>{agentInfo?.agent_hire_date}</Td>
            <Td>
              <span style={{ fontWeight: "bold" }}>Office State</span>
            </Td>
            <Td>{agentInfo?.agent_office_state}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Termination Date</span>
            </Td>
            <Td>{agentInfo?.agent_termination_date ? new Date(agentInfo.agent_termination_date).toLocaleDateString() : ''}</Td>
            <Td>
              <span style={{ fontWeight: "bold" }}>Office Street</span>
            </Td>
            <Td>{agentInfo?.agent_office_street}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Brokerage</span>
            </Td>
            <Td>{agentInfo?.agent_brokerage}</Td>
            <Td>
              <span style={{ fontWeight: "bold" }}>Office Zip Code</span>
            </Td>
            <Td>{agentInfo?.agent_office_zip_code}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Broker Name</span>
            </Td>
            <Td>{agentInfo?.agent_broker_name}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Broker Email</span>
            </Td>
            <Td>{agentInfo?.agent_broker_email}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Agent Phone</span>
            </Td>
            <Td>{agentInfo?.agent_phone}</Td>
          </Tr>
          <Tr>
            <Td>
              <span style={{ fontWeight: "bold" }}>Broker Phone</span>
            </Td>
            <Td>{agentInfo?.agent_broker_phone}</Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};
