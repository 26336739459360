import React, { useContext, useState } from "react";
import { flexRender } from "@tanstack/react-table";
import PropTypes from "prop-types";
import { appContext } from "../../AppProvider";
const allowedColumnSorting = [ "mls","agentTeam", "nameMatch", "agentName", "agentMls","listingAddress", "listingCloseDate", "transactionSeller", "transactionBuyer", "auditorStatus", "managerStatus", "finalDisposition"]

const BlankTableComponent = ({ table }) => {
    const {
        setSortColumn,
        setSortDirection,
        sortDirection,
        sortColumn
    } = useContext(appContext);
    const [currentSorting, setCurrentSorting] = useState({});
    
    const setSortingData = (header) => {
        const columnId = header.columnDef.recordDataId;
        const newSortDirection = (currentSorting[columnId] === 'asc') ? 'desc' : 'asc';
        setCurrentSorting({
            ...currentSorting,
            [columnId]: newSortDirection
        });

        setSortColumn(columnId);
        setSortDirection(newSortDirection);
    }

    const renderSortLogo = (column) => {
        if (column.columnDef.recordDataId !== sortColumn) { 
            return null;
        }
        if (sortDirection === 'asc') {
            return ' 🔼';
        } else if (sortDirection === 'desc') {
            return ' 🔽';
        } else {
            return null;
        }
    }

    return (
        <div className="record-list-container">
            <table id="index-table">
                <thead>
                    {table && table.getHeaderGroups() && table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {

                                const sortedHeader = allowedColumnSorting.includes(header.id)

                                return (
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : sortedHeader ? (
                                                <div
                                                    {...{
                                                        onClick: () => setSortingData(header.column)
                                                    }}
                                                >
                                                    {flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                                    {renderSortLogo(header.column)}
                                                </div>
                                            ) : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table && table.getRowModel() && table.getRowModel().rows && table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

BlankTableComponent.propTypes = {
    table: PropTypes.any.isRequired
};

export default BlankTableComponent;