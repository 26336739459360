// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.record-search-bar-container {
    width: 90vw;
    padding: 2vh 2vw;
    text-align: center;
    border-radius: 4px;
    border-top: 2px solid lightgrey;
    border-right: 2px solid lightgrey;
    border-left: 2px solid lightgrey;
}
.search-bar-width-limit{
    max-width: 28em;
    margin: 0 auto;
}

.record-search-bar {
    width: 100%;
    padding: 5px;
    margin: 5px;
    height: 40px;
    
}

.input-icon input[type="text"]:focus-visible{
    outline: 1px solid #15cc2c;
    border-radius: 2px;
}

.input-icon {
    position: relative;
}
  
.icon-search {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.icon-search path {
    stroke: rgb(32, 160, 47);
    fill: rgb(32, 160, 47);
}

.icon-close {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}


.icon-close path{
    stroke: red;
}`, "",{"version":3,"sources":["webpack://./src/containers/RecordSearchBar.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,+BAA+B;IAC/B,iCAAiC;IACjC,gCAAgC;AACpC;AACA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;AAC/B;AACA;IACI,wBAAwB;IACxB,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;AAC/B;;;AAGA;IACI,WAAW;AACf","sourcesContent":[".record-search-bar-container {\n    width: 90vw;\n    padding: 2vh 2vw;\n    text-align: center;\n    border-radius: 4px;\n    border-top: 2px solid lightgrey;\n    border-right: 2px solid lightgrey;\n    border-left: 2px solid lightgrey;\n}\n.search-bar-width-limit{\n    max-width: 28em;\n    margin: 0 auto;\n}\n\n.record-search-bar {\n    width: 100%;\n    padding: 5px;\n    margin: 5px;\n    height: 40px;\n    \n}\n\n.input-icon input[type=\"text\"]:focus-visible{\n    outline: 1px solid #15cc2c;\n    border-radius: 2px;\n}\n\n.input-icon {\n    position: relative;\n}\n  \n.icon-search {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n}\n.icon-search path {\n    stroke: rgb(32, 160, 47);\n    fill: rgb(32, 160, 47);\n}\n\n.icon-close {\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n}\n\n\n.icon-close path{\n    stroke: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
