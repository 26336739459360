import React, { createContext, useState } from "react";
import { useQuery, useSubscription } from "@apollo/client";
import {
    GET_AGENT_DATA,
    GET_GROUPED_AGENT_DATA,
    GET_GROUPED_AGENT_DATA_COUNT,
    GET_INDIVIDUAL_LISTINGS_DATA_COUNT
} from "./graphql/queries";
import { GET_INDIVIDUAL_LISTINGS_DATA } from "./graphql/subscriptions";
import PropTypes from "prop-types";
import { formatIndexPageFilters } from "./utils";
import { useLocation } from 'react-router-dom'; 


const appContext = createContext(undefined);

const AppProvider = ({ children }) => {
    const location = useLocation(); // This hook gives you access to the location object
    const shouldSkipQuery = location.pathname.includes('/listing');


    const [recordOffset, setRecordOffset] = useState(0);
    const [groupedRecordOffset, setGroupedRecordOffset] = useState(0);

    const [mlsFilter, setMlsFilter] = useState('%');
    const [agentFilter, setAgentFilter] = useState(null);
    const [teamFilter, setTeamFilter] = useState(null);
    const [status, setStatus] = useState({auditor:'', manager:'', finalDisposition:''});
    const [groupByAgent, setGroupByAgent] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');


    const [sortColumn, setSortColumn] = useState("mls_code");
    const [sortDirection, setSortDirection] = useState("asc");

    const queryFilters = formatIndexPageFilters(
        mlsFilter,
        agentFilter,
        teamFilter,
        status.auditor,
        status.manager,
        status.finalDisposition,
        searchTerm
    )

    var orderByColumn = '';
    if (sortColumn === 'transaction_count') { 
        orderByColumn = 'transaction_count';
    } else if (sortColumn === 'agent_warehouse_generated_team') {
        orderByColumn = 'agent_team';
    } else if (sortColumn === 'proposed_lead_ids') {
        orderByColumn = 'proposed_lead_ids';
    } else {
        orderByColumn = sortColumn;
    }

    const getIndividualRecordsQuery = GET_INDIVIDUAL_LISTINGS_DATA(
        orderByColumn,
        sortDirection,
        queryFilters
    );

    const {
        loading: recordsLoading,
        error: recordsError,
        data: recordsData,
        fetchMore: fetchMoreRecords,
        refetch
    } = useSubscription(getIndividualRecordsQuery, {
        variables: {
            offset: recordOffset,
            limit: 30,
        }
    });


    const getIndividualRecordsCountQuery = GET_INDIVIDUAL_LISTINGS_DATA_COUNT(queryFilters);
    const {
        loading: individualRecordsCountLoading,
        error: individualRecordsCountError,
        data: individualRecordsCountData
    } = useQuery(getIndividualRecordsCountQuery, {
        skip: shouldSkipQuery
    });

    if (recordsError) console.error(recordsError);

    if (individualRecordsCountError) console.error(individualRecordsCountError);

    const recordData = recordsData?.read_only_agent_auditor_listings_transactions_view;

    const individualRecordsCount = individualRecordsCountData?.read_only_agent_auditor_listings_transactions_view_aggregate?.aggregate?.count;

    const { loading: agentsLoading, error: agentsError, data: agentsData } = useQuery(GET_AGENT_DATA);

    if (agentsError) console.error(agentsError);

    const agentData = agentsData?.read_only_all_agents;

    const getGroupedRecordsQuery = GET_GROUPED_AGENT_DATA(sortColumn, sortDirection, queryFilters);
    const {
        loading: groupedRecordsLoading,
        error: groupedRecordsError,
        data: groupedRecordData,
        fetchMore: fetchMoreGroupedRecords
    } = useQuery(getGroupedRecordsQuery, {
        variables: {
            offset: groupedRecordOffset,
            limit: 30,
        },
        skip: shouldSkipQuery
    });

    const getGroupedAgentDataCountQuery = GET_GROUPED_AGENT_DATA_COUNT(queryFilters);
    const {
        loading: groupedRecordCountLoading,
        error: groupedRecordCountError,
        data: groupedRecordCountData,
    } = useQuery(getGroupedAgentDataCountQuery, {
        skip: shouldSkipQuery
    });

    if (groupedRecordCountError) console.error(groupedRecordCountError);

    if (groupedRecordsError) console.error(groupedRecordsError);


    const groupedRecordsData = groupedRecordData?.read_only_all_agents;

    const totalGroupedCount = groupedRecordCountData?.read_only_agent_auditor_listings_transactions_view_aggregate?.aggregate?.count;

    const Provider = appContext.Provider;

    return (
        <Provider
            value={{
                recordData,
                recordsLoading,
                fetchMoreRecords,
                sortColumn,
                setSortColumn,
                sortDirection,
                setSortDirection,
                refetch,

                groupedRecordsData,
                groupedRecordsLoading,
                fetchMoreGroupedRecords,

                totalGroupedCount,
                groupedRecordCountLoading,

                individualRecordsCount,
                individualRecordsCountLoading,

                recordOffset,
                setRecordOffset,

                groupedRecordOffset,
                setGroupedRecordOffset,

                agentData,
                agentsLoading,

                setAgentFilter,
                setMlsFilter,
                setTeamFilter,

                groupByAgent,
                setGroupByAgent,

                status,
                setStatus,
                queryFilters,

                searchTerm,
                setSearchTerm,
            }}
        >
            {children}
        </Provider>

    );
}

AppProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export { AppProvider, appContext };
