import React, { useContext, useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { nullFilter, clearFilter, auditorStatusOptions } from "../utils";
import { selectStyles } from "../styles";
import { appContext } from "../AppProvider";

const AuditorStatusFilter = ({ onFilterChange, value }) => {
  const navigate = useNavigate();

  const { setStatus, setGroupedRecordOffset, setRecordOffset } =
    useContext(appContext);

  const findSelectedOption = (selectedValues) => {
    if (!selectedValues) return null;
    if (selectedValues.includes("noStatus")) selectedValues = ['noStatus'];
    let result = clearFilter
      .concat(nullFilter.concat(auditorStatusOptions))
      .filter((option) => selectedValues.includes(option.value));
    return result;
  };

  const [selectedOption, setSelectedOption] = useState(
    findSelectedOption(value)
  );

  useEffect(() => {
    setSelectedOption(findSelectedOption(value));
  }, [value]);

  const handleAuditorStatusSelection = (selectedOptions) => {
    setGroupedRecordOffset(0);
    setRecordOffset(0);

    let selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    // If "Clear Filter" is selected, clear the input
    if (selectedValues.includes(false) || selectedOptions.length === 0) {
      selectedValues = '';
      selectedOptions = '';
    }

    setStatus((prevStatus) => ({ ...prevStatus, auditor: selectedValues }));
    onFilterChange({
      auditorStatus: selectedValues.length > 0 ? selectedValues : "noStatus",
    });
    setSelectedOption(selectedOptions);

    if (selectedValues.length === 0) {
      const currentParams = new URLSearchParams(location.search);
      currentParams.delete("auditorStatus");
      navigate(location.pathname + "?" + currentParams.toString());
    }
};

  return (
    <Select
      options={clearFilter.concat(nullFilter.concat(auditorStatusOptions))}
      placeholder={"Select Auditor Status"}
      onChange={handleAuditorStatusSelection}
      value={selectedOption}
      styles={selectStyles}
      isMulti
    />
  );
};

AuditorStatusFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

export default AuditorStatusFilter;