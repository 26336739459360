import React, { useEffect, useState } from "react";

import { Stack } from "@chakra-ui/react";

/* eslint-disable react/prop-types */
export const AddressInformation = ({ auditRecord }) => {
  const [auditRecordData, setAuditRecordData] = useState(null);

  useEffect(() => {
    if (auditRecord) {
      setAuditRecordData(auditRecord);
    }
  }, [auditRecord]);

  return (
    <Stack style={{ fontSize: "14px" }}>
      {auditRecordData ? (
        <>
          <div>
            <label>
              <div style={{ fontWeight: "bold" }}>
                Listing Address Information:
              </div>
              <span style={{ wordBreak: "normal" }}>
                {" "}
                {auditRecordData?.length == 0 ||
                auditRecordData.listing_address == null ? (
                  "No Data"
                ) : (
                  <span>
                    {auditRecordData.listing_address},{" "}
                    {auditRecordData.listing_city},{" "}
                    {auditRecordData.listing_state}
                  </span>
                )}
              </span>
            </label>
          </div>
          <div>
            <label>
              <div style={{ fontWeight: "bold" }}>
                Parcel Address Information:
              </div>
              <span>
                {auditRecordData?.length == 0 ||
                auditRecordData.parcel_address == null ? (
                  "No data"
                ) : (
                  <span>
                    {auditRecordData.parcel_address},{" "}
                    {auditRecordData.parcel_city},{" "}
                    {auditRecordData.parcel_state}
                  </span>
                )}
              </span>
            </label>
          </div>
          {/* <Checkbox> match?</Checkbox> */}
        </>
      ) : (
        "Loading..."
      )}
    </Stack>
  );
};
